import { lazy } from 'react';

const assetReceivedButNotYetInvoiced = lazy(() =>
  import('../pages/AssetReceivedButNotYetInvoiced/index.js').then((module) => ({
    default: module.AssetReceivedButNotYetInvoiced,
  })),
);

const assetReceivedButNotYetInvoicedRoute = {
  public: [],
  auth: [
    {
      name: 'advances_to_vendors',
      path: '/finance-and-accounting/asset-received-but-not-yet-invoiced',
      component: assetReceivedButNotYetInvoiced,
    },
  ],
};

export default assetReceivedButNotYetInvoicedRoute;
