import makeApiRequest from './makeApiCall';

export const getAdvancesFormCustomers = async (data) => {
  //endpoint => declaration of api
  return await makeApiRequest(`/advances-from-customers`, 'POST', null, data);
};

export const getAdvancesFormCustomersExport = async (data) => {
  //endpoint => declaration of api
  return await makeApiRequest(
    `/advances-from-customers/export`,
    'POST',
    null,
    data,
  );
};

export const getAdvancesFormCustomersJv = async (data) => {
  //endpoint => declaration of api
  return await makeApiRequest(
    `/advances-from-customers/jv`,
    'POST',
    null,
    data,
  );
};
