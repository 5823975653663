const DropdownCustom = ({
  data,
  valueField,
  nameField,
  selected,
  name,
  placeholder,
  onChange,
}) => {
  placeholder =
    typeof placeholder === 'undefined' ? '- Select Option - ' : placeholder;
  return (
    <select
      className="form-control form-control-sm"
      name={name}
      id={name}
      value={selected}
      onChange={onChange}
    >
      <option value={''}>{placeholder}</option>
      {data.map((row) => (
        <option value={row[valueField]}>{row[nameField]}</option>
      ))}
    </select>
  );
};

export default DropdownCustom;
