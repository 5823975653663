import { lazy } from 'react';

const CWT = lazy(() => import('../pages/CWT/index'));

const cwtRoute = {
  public: [],
  auth: [
    {
      name: 'cwt',
      path: '/finance-and-accounting/cwt',
      component: CWT,
    },
  ],
};

export default cwtRoute;
