// Master Databases
export const productPermission = [
  'view_products',
  'get_specific_product',
  'add_product',
  'update_product',
  'update_status',
];

export const customerPermission = [
  'view_customers_list',
  'add_customer',
  'get_all_customers',
  'get_customer',
  'update_customer',
  'customer_set_status_active',
  'customer_set_status_deactivate',
  'customer_list_export',
  'customer_deals_and_discounts_regular_orders',
  'deals_and_discount_logs',
  'customer_center',
];

export const subLedgerJournalPermission = [
  'view_sales_special_journal_list',
  'export_sales_special_journal_list',
];

export const subLedgerReceivablePermission = [
  'view_receivable_sub_ledger_list',
  'export_receivable_sub_ledger_list',
];

export const subLedgerInventoryPermission = [
  'view_inventory_sub_ledger_list',
  'export_inventory_sub_ledger_list',
];

export const salePerProductPermission = [
  'view_sales_per_product_ledger_list',
  'export_sales_per_product_ledger_list',
];

export const vatableSalesPermission = [
  'view_vatable_sales_ledger_list',
  'export_vatable_sales_ledger_list',
];

export const productLedgerPermission = [
  'view_product_ledger_list',
  'export_product_ledger_list',
];

export const ytdSalesLedgerPermission = [
  'view_ytd_sales_ledger_list',
  'export_ytd_sales_ledger_list',
];
export const customerProductMaintenance = ['view_products_maintenance'];

export const warehousePermission = [
  'view_warehouses',
  'view_specific_warehouse',
  'add_warehouse',
  'update_warehouse',
  'delete_warehouse',
  'create_warehouse',
];

export const zoneAsssignmentPermission = [
  'get_zone_assignments',
  'add_zone_assignment',
  'get_specific_zone',
  'update_zone_assigment',
  'get_zone_type_list',
];

// Inventory
export const inventoryReceiptPermission = [
  'receipt_list',
  'view_approval_list',
  'create_receipt',
  'update_receipt_cm',
  'create_receipt_inspection',
  'approve_receipt',
];

export const inventoryReceiptInspectionPermission = [
  'view_inspection',
  'create_receipt_inspection',
  'update_receipt_inspection',
];

export const inventoryReceiptApprovalPermission = ['view_inspection_approval'];

export const inventoryAdjustmentPermission = [
  'view_inventory_adjustment',
  'view_inventory_adjustment_approval',
  'view_inventory_adjustment_approved',
];

export const inventoryReportPermission = [
  'view_inventory_report',
  'view_inventory_report_generator',
];

export const inventoryReportMovementPermission = [
  'view_inventory_report_movement',
];

export const inventoryReportMovementTransactionLogPermission = [
  'view_inventory_movement_transaction_log',
];

export const inventoryImportPermission = [
  'create_inventory_import',
  'view_inventory_import',
];

export const inventoryTransferPermission = [
  'transfer_list',
  'view_transfer_request',
  'create_transfer',
  'update_receipt_dm',
  'approve_transfer',
  'cancel_transfer',
];

export const inventoryTransferRequestPermission = [
  'create_inventory_transfer_v2',
  'view_inventory_transfer_list',
  'view_inventory_transfer_confirmation',
  'view_inventory_transfer_confirmed',
  'view_inventory_transfer_received',
  'view_inventory_transfer_in_transit',
  'view_inventory_transfer_posted',
  'view_inventory_transfer_cancelled',
  'view_inventory_transfer_closed',
];

export const inventoryTransferApprovalPermission = ['view_inventory_transfer'];

export const warehouseAndPalletAssignmentPermission = [
  'view_warehouse_pallet_assignment',
];

// Credit and Collections
export const customerListPermission = [
  'view_customer_collection_dashboard',
  'view_customer_ars',
  'show_customer_list',
  'get_customer_outstanding_balance',
  'get_customer_advances',
  'get_customer_returns',
  'get_customer_ewt',
  'get_customer_check',
  'get_customer_transactionhistory',
  'edit_customer_footprint',
  'get_customer_footprint',
  'delete_customer_footprint',
];

export const collectionListPermission = [
  'get_collection_list',
  'get_collection_details',
  'get_collection_details_ar',
  'get_pdc_status',
  'update_pdc_status',
  'update_collection_details',
  'upload_deposit_slip',
  'create_collection_footprint',
  'get_collection_dashboard_totals',
  'get_list_of_chod',
  'get_list_of_dm',
  'get_customer_advances_report',
];

export const customerSummaryPermission = [
  'get_customer_outstanding_balance',
  'get_list_of_chod',
  'get_customer_check',
  'get_customer_advances',
  'get_customer_returns',
  'get_customer_ewt',
  'get_customer_transactionhistory',
  'get_customer_footprint',
];

export const customerAgingPermission = [
  'get_customer_outstanding_balance',
  'get_list_of_chod',
  'get_customer_check',
  'get_customer_advances',
  'get_customer_returns',
  'get_customer_ewt',
  'get_customer_transactionhistory',
  'get_customer_footprint',
];

export const paymentApplicationPermission = [
  'get_list_of_bank_branches',
  'get_all_banks',
  'add_bank_branch',
  'get_payment_details',
  'get_all_remittance_modes',
  'post_payment',
  'get_deduction_types',
  'get_deduction_remarks',
  'get_reference_number',
  'get_collection_type',
  'update_payment_details',
  'view_payment_application',
];

export const salesReturnPermission = [
  'srpf_approval_list',
  'srpf_approved_list',
  'srpf_declined_list',
  'srpf_list',
  'view_srpf',
  'create_srpf',
  'update_srpf',
  'approve_Srpf',
];

export const treasuryListPermission = [
  'get_pending_for_received_payments',
  'get_received_payments',
  'get_redeposited_payments',
  'get_pdc-on_hand_payments',
  'get_deposited_payments',
  'get_identified-direct_deposit_payments',
  'get_unidentified_deposits',
  'get_cleared_check_payments',
  'get_returned_payments',
];

export const treasuryListApprovalPermission = [
  'list_of_approval_request',
  //"get_approval_request_details",
  // "update_status_of_request",
];

export const creditMemoPermission = [
  'get_credit_memo_approval',
  'get_credit_memo_approved',
  'get_deduction_types_cm',
  'get_period_covered_so_cm',
  'update_cm',
  'get_view_cm',
  'credit_memo_get_advances',
  'credit_memo_approvals',
  'credit_memo_export',
  'get_credit_memo_retro_approval',
  'get_credit_memo_retro_approved',
  'approve_retro_creditMemo',
  'can_disapprove_cm',
];

export const debitMemoPermission = [
  'get_debit_memo_approval',
  'get_debit_memo_approved',
  'get_debit_memo_retro_approval',
  'get_credit_debit_retro_approved',
];

export const financeDebitMemoPermission = [
  'get_finance_dm_list',
  'print_finance_dm',
  'create_finance_dm',
];

export const salesInvoiceManualEntryPermission = ['sales_invoice_manual_entry'];

export const customerDealsAndDiscountPermissionRegular = [
  'view_customer_deals_and_discounts_regular',
];

export const customerDealsAndDiscountPermission = [
  'view_customer_deals_and_discounts',
];

export const customerDealsAndDiscountPromoPermission = [
  'view_customer_deals_and_discounts_new_promo',
];

export const courierPermission = [
  'view_courier_list',
  'export_courier_list',
  'create_courier',
  'update_courier',
  'delete_courier',
];

export const courierLocationPermission = [
  'view_courier_location_list',
  'export_courier_location_list',
  'create_courier_location',
  'update_courier_location',
];

export const customerCourierPermission = [
  'view_customer_courier_list',
  'export_customer_courier_list',
  'create_customer_courier_list',
];

export const customerBranchesPermission = [
  'get_customer_branches',
  'store_customer_branches',
  'update_branches',
  'unassociate_customers',
  'customer_config',
  'show_customer_config',
];

export const cncCollectionListPermission = [
  'get_collection_list',
  'get_list_of_chod',
  'get_list_of_dm',
];

export const customerAdvancesPermission = [
  'get_advances_report',
  'export_advances_report',
];

export const cncCustomerListPermission = ['view_customer_collection_dashboard'];
export const cncStatementOfAccountPermission = ['view_soa'];
export const chartOfAccountPermission = ['can_view_coa'];
export const chartOfAccountTaggingPermission = ['can_view_coa_tagging'];
export const assetManagementPurchaseOrderNSPermission = [
  'get po non stock list',
  'get_po_non_stock_list',
];

export const assetManagementSubledgerPermission = ['get_asset_subledger'];
export const assetManagementListingsPermission = [
  'get po non stock list',
  'get po schedule',
  'get_po_non_stock_list',
];
export const assetSubledgerPermission = ['get_asset_subledger'];
export const tradeAndNonTradePermission = ['trade_non_trade'];
export const assetExpenseSubledgerPermission = ['get_expense_subledger'];
export const journalVoucherPermission = ['jv_list', 'jv_or_list'];
export const checkVoucherPermission = [
  'check_voucher_clear_release',
  'check_voucher_status_update',
  'check_voucher_print',
  'check_voucher_print_cheque',
  'check_voucher_show',
  'check_voucher_list',
  'check_voucher_coa_list',
  'check_voucher_pv',
  'check_voucher_payee_pv_list',
  'check_voucher_payees',
  'check_voucher_pv_list',
  'check_voucher_store_dm',
  'check_voucher_pv_list_export',
  'check_voucher_export',
  'check_voucher_store',
];

export const costCenterPermission = ['get_all_cost_centers'];
export const divisionsPermission = ['get_all_division'];
export const programProjectPermission = [
  'get_program_list',
  'export_program_list',
  'get_program_list_dropdown',
  'create_program',
  'update_program',
];

export const purchaseRequestPermssion = ['can_view_purchase_request'];
export const purchaseOrderPermission = ['can_view_purchase_order'];
export const balanceSheetPermission = ['get_balance_sheet'];
export const inventoryInvoicedSubledgerPermission = [
  'inventory_invoiced_subledger',
];

export const coaTaggingPermission = [];
export const transactionListingPermission = ['get_transaction_listing'];
export const ewtSubledgerPermission = ['view_ewt_subledger'];
export const ewtV2SubledgerPermission = ['get_ewt_expanded', 'get_ewt_all'];

export const payableVoucherPermission = [
  'can_view_payable_voucher',
  'can_view_payable_voucher_approval',
  'can_view_payable_voucher_approved',
  'can_view_payable_voucher_returned',
  'can_view_payable_voucher_cancelled',
  'can_view_payable_voucher_or_listing',
  'can_export_payable_voucher',
  'can_export_payable_voucher_or',
  'can_create_payable_voucher',
  'can_edit_all_fields',
  'can_save_payable_voucher',
  'can_update_payable_voucher',
  'can_approve_payable_voucher',
  'can_decline_payable_voucher',
  'can_cancel_payable_voucher',
];

export const creditMemoSubLedgerPermission = [
  'can_view_credit_memo_sub_ledger',
];

export const liabilitySubledgerPermission = ['can_view_credit_memo_sub_ledger'];

export const vendorDatabasePermission = [
  'can_view_vendor_database',
  'can_create_vendor_database',
  'can_edit_vendor_database',
  'can_disable_vendor_database',
  'can_export_vendor_database',
  'can_create_product_category',
  'can_update_vendor_database',
];

export const supplierDatabasePermission = [
  'can_view_supplier_database',
  'can_create_supplier_database',
  'can_edit_supplier_database',
  'can_disable_supplier_database',
  'can_export_supplier_database',
  'can_update_supplier_database',
];
export const payeeDatabasePermission = [
  'can_view_payee_database',
  'can_create_payee_database',
  'can_edit_payee_database',
  'can_disable_payee_database',
  'can_export_payee_database',
  'can_update_payee_database',
];
export const trialBalancePermission = [
  'get_trial_balance',
  'export_trial_balance',
];

export const incomeStatementPermission = ['get_income_statement'];

export const generalLedgerPermission = ['get_general_ledger'];

export const bankDatabasePermission = ['get_bank_database'];
export const userDatabasePermission = [
  'get_all_users',
  'add_user',
  'change_password',
  'user_role',
];
export const cwtSubledgerPermission = [
  'get_cwt_all',
  'get_cwt_expanded',
  'view_cwt_subledger',
];
export const cashInBankSubLedgerPermission = ['can_view_cash_in_bank'];

export const statementOfAccountPermission = ['view_statement_of_account'];

export const capitalizedPermission = ['view_capitalized'];
export const allocatedExpensesPermission = ['view_allocated_expenses'];
export const separationOfTradeNonTradePayablePermission = [
  'view_separation_of_trade_non_trade_payable',
];

export const advancesSupplierSubledgerPermission = [
  'advances_supplier_subledger',
];

export const cwtReportAndUpdatingModuleFinanceReportPermission = [
  'cwt_report_and_updating_module',
  'cwt_report_and_updating_module_export',
];
export const creditableWithholdingTax = ['get_creditable_withholding_tax'];
export const ewtPayableReport = ['get_ewt_payable_report'];
export const inventoryReceivedNotYetInvoicedPermission = [
  'get_ir_not_yet_invoiced',
];
export const outputVatPermission = ['get_output_vat'];
export const inputVatPermission = ['get_input_vat'];
export const advancesToVendorPermission = ['get_advances_to_vendor'];
export const assetReceivedButNotYetInvoicedPermission = [
  'asset_received_but_not_yet_invoiced',
];
export const cashAdvancesPermission = [
  'can_view_cash_advance_setup',
  'can_view_cash_advance_override',
  'can_view_cash_advance_subledger_employee',
  'cash_advanced_aging',
];
export const undefinedDepositSubledgderPermission = [
  'undefined_deposit_subledgder',
];

export const undefinedDepositV2Permission = ['undefined_deposit_subledger_v2'];

export const advancesFromCustomerPermission = ['get_advances_from_customers'];
export const advancesToSuppliersPermission = ['get_advances_to_suppliers'];
