import { EWT } from '../pages/EWT';

const ewtSubledgerRoute = {
  public: [],
  auth: [
    // {
    //   name: 'ewt_subledger',
    //   path: '/finance-and-accounting/ewt-subledger',
    //   component: EWTSubledger,
    // },
    {
      name: 'ewt',
      path: '/finance-and-accounting/ewt',
      component: EWT,
    },
  ],
};

export default ewtSubledgerRoute;
