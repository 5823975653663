import { lazy } from 'react';

const AdvancesToVendors = lazy(() =>
  import('../pages/AdvancesToVendors/index.js').then((module) => ({
    default: module.AdvancesToVendors,
  })),
);

const advancesToVendorsRoute = {
  public: [],
  auth: [
    {
      name: 'advances_to_vendors',
      path: '/finance-and-accounting/advances-to-vendors',
      component: AdvancesToVendors,
    },
  ],
};

export default advancesToVendorsRoute;
