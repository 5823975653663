import { lazy } from 'react';

const CashAdvanceSetup = lazy(() =>
  import('../pages/CashAdvanceSubLedger/Setup.js').then((module) => ({
    default: module.CashAdvanceSetup,
  })),
);
const CashAdvanceOverride = lazy(() =>
  import('../pages/CashAdvanceSubLedger/Override.js').then((module) => ({
    default: module.CashAdvanceOverride,
  })),
);
const CashAdvanceSubledgerEmployee = lazy(() =>
  import('../pages/CashAdvanceSubLedger/Employee.js'),
);
const CashAdvanceSubledgerAll = lazy(() =>
  import('../pages/CashAdvanceSubLedger/All.js'),
);

const cashAdvanceSubLedgerRoute = {
  auth: [
    {
      name: 'cash_advance_setup',
      path: '/finance-and-accounting/cash-advance/setup',
      component: CashAdvanceSetup,
    },
    {
      name: 'cash_advance_override',
      path: '/finance-and-accounting/cash-advance/override',
      component: CashAdvanceOverride,
    },
    {
      name: 'cash_advance_subledger_employee',
      path: '/finance-and-accounting/cash-advance/subledger/employee',
      component: CashAdvanceSubledgerEmployee,
    },
    {
      name: 'cash_advance_subledger_all',
      path: '/finance-and-accounting/cash-advance/subledger/all',
      component: CashAdvanceSubledgerAll,
    },
  ],
};

export default cashAdvanceSubLedgerRoute;
