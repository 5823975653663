import { combineRoutes } from '../utils/routeUtil';

// routess
import authRoute from './authRoute';
import bankRoute from './bankRoute';
import courierRoute from './courierRoute';
import creditCollectionRoute from './creditCollectionRoute';
import customerAgingRoute from './customerAgingRoute';
import dealsAndDiscountLogsRoute from './dealsAndDiscountLogsRoute';
import dealsAndDiscountRoute from './dealsAndDiscountRoute';
import DebitMemoRoute from './debitMemoRoute';
import generalLedgerRoute from './generalLedgerRoute';
import guestRoute from './guestRoute';
import inventoryRoute from './inventoryRoute';
import ledgerRoute from './ledgerRoute';
import oldRoute from './oldRoute';
import payableManagementRoute from './payableManagementRoute';
import permissionRoute from './permissionRoute';
import purchaseOrderRoute from './purchaseOrderRoute';
import purchasingRoute from './purchasingRoute';
import salesInvoiceRoute from './salesInvoiceRoute';
import smppProductMaintenanceRoute from './smppProductMaintenanceRoute';
import systemNotificationRoute from './systemNotificationRoute';
import tokenRoute from './tokenRoute';
import treasuryRoute from './treasuryRoute';
import trialBalanceRoute from './trialBalanceRoute';
import vendorRoute from './vendorRoute';
import warehouseRoute from './warehouseRoute';

// all accounting route will go under this comment
import chartOfAccountRoute from './chartOfAccountRoute';
import checkVoucherRoute from './checkVoucherRoute';
import costCenterRoute from './costCenterRoute';
import divisionRoute from './divisionRoute';
import payableVoucherRoute from './payableVoucherRoute';

// SRPF ROUTE
import InventoryInvoicedSubledgerRoute from './InventoryInvoicedSubledgerRoute';
import advancesSubledgderRoute from './advancesSubledgderRoute';
import advancesToVendorsRoute from './advancesToVendorsRoute';
import assetManagementRoute from './assetManagementRoute';
import assetReceivedButNotYetInvoicedRoute from './assetReceivedButNotYetInvoicedRoute';
import balanceSheetNewFormatRoute from './balanceSheetNewFormatRoute';
import balanceSheetRoute from './balanceSheetRoute';
import cashAdvanceSubLedgerRoute from './cashAdvanceSubLedgerRoute';
import cashAdvancedAgingRoute from './cashAdvancedAgingRoute';
import cashInBankSubledgerRoute from './cashInBankSubledgerRoute';
import coaTaggingRoute from './coaTaggingRoute';
import creditMemoSubledgderRoute from './creditMemoSubledgderRoute';
import cwtRoute from './cwtRoute';
import cwtSubledgerRoute from './cwtSubledgerRoute';
import ewtSubledgerRoute from './ewtSubledgerRoute';
import expenseSubledgerRoute from './expenseSubledgerRoute';
import incomeStatementRoute from './incomeStatementRoute';
import inputVatRoute from './inputVatRoute';
import inventoryReceivedNotYetInvoicedRoute from './inventoryReceivedNotYetInvoicedRoute';
import journalVoucherRoute from './journalVoucherRoute';
import liabilitySubledgerRoute from './liabilitySubledgerRoute';
import outputVatRoute from './outputVatRoute';
import programProjectRoute from './programProjectRoute';
import srpfRoute from './srpfRoute';
import statementOfAccountRoute from './statementOfAccountRoute';
import tradeNonTradePayableRoute from './tradeNonTradePayableRoute';
import transactionListingRoute from './transactionListingRoute';
import creditMemoMonitoringReportRoute from './creditMemoMonitoringReportRoute';
import undefinedDepositSubLedgerReportRoute from './undefinedDepositSubLedgerReportRoute';
import undefinedDepositSubledgerV2Route from './undefinedDepositSubledgerV2Route';

//routes
export default combineRoutes([
  oldRoute,
  bankRoute,
  inventoryRoute,
  ledgerRoute,
  trialBalanceRoute,
  generalLedgerRoute,
  customerAgingRoute,
  courierRoute,
  creditCollectionRoute,
  treasuryRoute,
  guestRoute,
  tokenRoute,
  salesInvoiceRoute,
  smppProductMaintenanceRoute,
  systemNotificationRoute,
  dealsAndDiscountRoute,
  permissionRoute,
  warehouseRoute,
  authRoute,
  journalVoucherRoute,
  dealsAndDiscountLogsRoute,
  DebitMemoRoute,
  authRoute,
  vendorRoute,
  purchaseOrderRoute,
  dealsAndDiscountLogsRoute,
  chartOfAccountRoute,
  srpfRoute,
  balanceSheetRoute,
  incomeStatementRoute,
  checkVoucherRoute,
  payableManagementRoute,
  purchasingRoute,
  programProjectRoute,
  coaTaggingRoute,
  costCenterRoute,
  divisionRoute,
  assetManagementRoute,
  payableVoucherRoute,
  costCenterRoute,
  divisionRoute,
  chartOfAccountRoute,
  assetManagementRoute,
  expenseSubledgerRoute,
  liabilitySubledgerRoute,
  balanceSheetNewFormatRoute,
  advancesSubledgderRoute,
  creditMemoSubledgderRoute,
  statementOfAccountRoute,
  InventoryInvoicedSubledgerRoute,
  tradeNonTradePayableRoute,
  transactionListingRoute,
  cwtSubledgerRoute,
  ewtSubledgerRoute,
  cashInBankSubledgerRoute,
  creditMemoMonitoringReportRoute,
  cwtRoute,
  inventoryReceivedNotYetInvoicedRoute,
  outputVatRoute,
  inputVatRoute,
  advancesToVendorsRoute,
  assetReceivedButNotYetInvoicedRoute,
  cashAdvanceSubLedgerRoute,
  cashAdvancedAgingRoute,
  undefinedDepositSubLedgerReportRoute,
  undefinedDepositSubledgerV2Route,
]);
