import { getAdvancesFormCustomersExport } from '../../api/advancesForCustomers';
import { useState } from 'react';
import moment from 'moment';
import { useEWTQuery } from './const';
import { toast } from 'react-toastify';
import EWTScreen from './Screens/EWTScreen';

export const EWT = () => {
  const [filter, setFilter] = useState({
    customer_code: '',
    date_from: moment().subtract(6, 'month').format('YYYY-MM-DD'),
    date_to: moment().format('YYYY-MM-DD'),
  });
  const [tableParams, setTableParams] = useState({
    ...filter,
    page: 0,
    page_size: 50,
    date_from: moment().subtract(6, 'month').format('YYYY-MM-DD'),
    date_to: moment().format('YYYY-MM-DD'),
    filter: '',
    direction: '',
  });

  const { isFetching, data } = useEWTQuery(tableParams);

  const handleTableFilterChange = (filterModel) => {
    setTableParams({ ...tableParams, filterModel });
  };

  const handleTableChange = (params) => {
    setTableParams({ ...tableParams, ...params });
    console.log('handleTableChange ', tableParams);
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilter({ ...filter, [name]: value, page: 0, page_size: 50 });
  };

  const handleSearch = () => {
    setTableParams({ ...tableParams, ...filter, page: 0, page_size: 50 });
  };

  const handleExport = async () => {
    try {
      toast.loading('Please wait ...');

      const res = await getAdvancesFormCustomersExport({
        ...tableParams,
        ...filter,
      });
      const { code, result } = res.data.response;

      if (code !== '00000') {
        throw new Error(result);
      }

      window.location.href = result;
      toast.dismiss();
    } catch (error) {
      toast.dismiss();
      toast.error(error.message);
    }
  };

  return (
    <EWTScreen
      tableData={data}
      filter={filter}
      isLoading={isFetching}
      onTableFilter={handleTableFilterChange}
      onTableChange={handleTableChange}
      onFilterChange={handleFilterChange}
      onSearch={handleSearch}
      onExport={handleExport}
    />
  );
};
