import moment from 'moment';
import { numberFormatCurrency, toFixed } from '../../utils/helper';
import { useQuery } from 'react-query';
import { getEwt } from '../../api/ewtSubledger';

export const columns = [
  {
    field: 'customer_code',
    headerName: 'Customer Code',
    minWidth: 150,
    flex: 1,
    sortable: false,
  },
  {
    field: 'customer_name',
    headerName: 'Customer Name',
    minWidth: 200,
    flex: 1,
    sortable: false,
  },
  {
    field: 'setup_date',
    headerName: 'Set-up Date',
    minWidth: 120,
    flex: 1,
    type: 'date',
    sortable: false,
    renderCell: ({ row }) => {
      if (!row.setup_date) return '';

      return moment(row.setup_date).format('MM/DD/YYYY');
    },
  },
  {
    field: 'setup_reference',
    headerName: 'Set-up Reference',
    minWidth: 150,
    flex: 1,
    sortable: false,
  },
  {
    field: 'reversal_date',
    headerName: 'Reversal Date',
    minWidth: 120,
    flex: 1,
    type: 'date',
    sortable: false,
    renderCell: ({ row }) => {
      if (!row.reversal_date) return '';

      return moment(row.reversal_date).format('MM/DD/YYYY');
    },
  },
  {
    field: 'reversal_reference',
    headerName: 'Reversal References',
    minWidth: 150,
    flex: 1,
    sortable: false,
  },
  {
    field: 'particulars',
    headerName: 'Particulars',
    minWidth: 250,
    flex: 1,
    sortable: false,
  },
  {
    field: 'debit',
    headerName: 'Debit',
    minWidth: 150,
    flex: 1,
    type: 'number',
    sortable: false,
    renderCell: ({ row }) => numberFormatCurrency(row.debit ?? ''),
  },
  {
    field: 'credit',
    headerName: 'Credit',
    minWidth: 150,
    flex: 1,
    type: 'number',
    sortable: false,
    renderCell: ({ row }) => numberFormatCurrency(row.credit ?? ''),
  },
  {
    field: 'running_balance',
    headerName: 'Running Balance Per Set Up',
    minWidth: 250,
    flex: 1,
    type: 'number',
    renderCell: ({ row }) => numberFormatCurrency(row.running_balance ?? ''),
    sortable: false,
    disableColumnMenu: true,
  },
];

export const useEWTQuery = (tableParams) => {
  return useQuery({
    queryKey: ['ewt_sl', tableParams],
    queryFn: async () => {
      const res = await getEwt(tableParams);

      const { code, result, total_rows } = res.data.response;

      if (code !== '00000') {
        //throw new Error('Unable to load output vat.');
        return Promise.reject(new Error('Unable to load ewt data.'));
      }

      return {
        result: result.map((row) => ({
          ...row,
          customer_code: row.customer_code,
          setup_date: row.setup_date,
          setup_reference: row.setup_reference,
          reversal_date: row.transaction_date,
          reversal_reference: row.reversal_reference,
          debit: toFixed(row.debit),
          credit: toFixed(row.credit),
          running_balance: toFixed(row.running_balance),
        })),
        total_rows,
      };
    },
    throwOnError: true,
    placeholderData: { result: [], total_rows: 0 },
  });
};
