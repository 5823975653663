import { lazy } from 'react';

const OutputVat = lazy(() => import('../pages/OutputVat'));

const outputVatRoute = {
  public: [],
  auth: [
    {
      name: 'output_vat',
      path: '/finance-and-accounting/output-vat',
      component: OutputVat,
    },
  ],
};

export default outputVatRoute;
