import makeApiRequest from './makeApiCall';

export const getAllSupplier = async (data) => {
  //endpoint => declaration of api
  return await makeApiRequest(`/ewt_subledger/all`, 'POST', null, data);
};

export const getHighLevel = async (data) => {
  //endpoint => declaration of api
  return await makeApiRequest(`/ewt_subledger/high_level`, 'POST', null, data);
};

export const getExpanded = async (data) => {
  //endpoint => declaration of api
  return await makeApiRequest(`/ewt_subledger/expanded`, 'POST', null, data);
};

export const getAllSupplierDropdown = async () => {
  //endpoint => declaration of api
  let data = {
    status: 0,
  };
  return await makeApiRequest(`vendor-db/index`, 'GET', null, data);
};

export const getEwt = async (params) => {
  return await makeApiRequest(`/ewt-payable/subledgers`, 'POST', null, params);
};
