import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import GeneralLedgerReportScreen from '../Screens/GeneralLedgerReportScreen';
import Swal from 'sweetalert2';
import {
  requestReceivablesExport,
  getGeneralLedgerData,
  requestGeneralLedgerExport,
} from '../../../api/ledgers';
import { numberFormatCurrency } from '../../../utils/helper';
import { toast } from 'react-toastify';
import _ from 'lodash';
import useNavActive from '../../../components/Navbar/useNavActive';
import moment from 'moment';

const currentDate = moment().format('YYYY-MM-DD');

const formDefault = {
  date_from: currentDate,
  date_to: currentDate,
  page: 0,
  page_size: 50,
  order_by: 'account_code',
  direction: 'asc',
};

const General = () => {
  //declaration
  const history = useHistory();
  const [formData, setFormData] = useState(formDefault);
  const [generalLedgerData, setGeneralLedgerData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRow, setTotalRow] = useState(0);
  const [reloadTable, setReloadTable] = useState(1);
  const [columnRunning, setColumnRunning] = useState(true);
  const [pageArray, setPageArray] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentPageSize, setCurrentPageSize] = useState(50);
  const [pages, setPages] = useState([]);
  const [begBal, setBegBal] = useState(0);
  const [tableParams, setTableParams] = useState({});

  useNavActive([
    'finance_and_accounting',
    'fna_financial_reports',
    'general_ledger',
  ]);

  //functions
  //functions
  useEffect(() => loadLedgerData(formData), []);

  const loadLedgerData = async (form) => {
    if (form.account_code) {
      setColumnRunning(false);
    } else {
      setColumnRunning(true);
    }

    setReloadTable(Math.floor(Math.random() * 101));
    setLoading(true);
    setGeneralLedgerData([]);
    let dateFrom = new Date(form.date_from);
    let dateTo = new Date(form.date_to);
    let allow = 0;
    if (dateFrom.getTime() === dateTo.getTime()) {
      allow = 1;
    } else if (dateFrom.getTime() < dateTo.getTime()) {
      allow = 1;
    }

    if (allow === 1) {
      try {
        let res = await getGeneralLedgerData(form);
        const { code, result, total_rows } = res.data;

        if (code !== '00000') {
          toast.error(result);
          return;
        }

        let tmp = [];
        _.each(
          result,
          (row) => {
            tmp.push({
              id: row.id,
              journal_no: row.journal_entry,
              transaction_date: row.transaction_date,
              account_code: row.account_code,
              account_name: row.account_name,
              debit: row.debit,
              credit: row.credit,
              running_balance: row.running_balance,
              module: row.module,
              reference: row.reference,
              payee: row.payee,
              md: row.md,
              particulars: row.particulars,
              remarks: row.remarks,
              details: row.details,
              program: row.program,
              cost_center: row.cost_center,
              bank_date: row.bank_date,
              accounting_code: row.accounting_code,
            });
          },
          (row) => console.log('row', row),
        );

        setGeneralLedgerData(result);

        setTotalRow(total_rows);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast.error('Error occured. Please try again later');
      } finally {
        setLoading(false);
      }
    } else {
      setLoading(false);
      toast.warning('Please provide valid date!');
    }
  };

  const handleSelectedTab = (key) => {
    history.push('/finance-and-accounting/general-ledger');
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const getDate = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const searchLedger = (temp) => {
    toast.loading('Getting Transaction, Please wait...', {
      position: toast.POSITION.TOP_RIGHT,
    });
    if (formData.date_to === '') {
      getGeneralLedgerData(temp)
        .then((res) => {
          if (res.data.response.code === '00000') {
            if (res.data.response.result.length === 0) {
              toast.warning('No Data Available');
            }
            toast.dismiss();
            setGeneralLedgerData(res.data.response.result);
          } else {
            Swal.fire({
              title: 'Error',
              text: res,
              icon: 'error',
            });
          }
        })
        .catch((err) => {});
    } else {
      getGeneralLedgerData(formData)
        .then((res) => {
          if (res.data.response.code === '00000') {
            if (res.data.response.result.length === 0) {
              toast.warning('No Data Available');
            }
            toast.dismiss();
            setGeneralLedgerData(res.data.response.result);
          } else {
            Swal.fire({
              title: 'Error',
              text: res,
              icon: 'error',
            });
          }
        })
        .catch((err) => {});
    }
  };

  const handleSearch = () => loadLedgerData(formData);

  const handleDataTableChange = (params) => {
    let temp = {
      ...formData,
      ...params,
    };
    loadLedgerData(temp);
  };

  const handleTableChange = (params) => {
    const newParams = { ...formData, ...params };

    setTableParams(newParams);
    loadLedgerData(newParams);
  };

  const handleFilterModelChange = (filterModel) => {
    const newParams = { ...formData, filter: filterModel };
    setTableParams(newParams);
    loadLedgerData(newParams);
  };

  const OnHandleExport = () => {
    let dateFrom = new Date(formData.date_from);
    let dateTo = new Date(formData.date_to);
    let allow = 0;
    if (dateFrom.getTime() === dateTo.getTime()) {
      allow = 1;
    } else if (dateFrom.getTime() < dateTo.getTime()) {
      allow = 1;
    }
    if (allow === 0) {
      Swal.fire({
        title: 'Warning',
        text: 'Please select correct dates!',
        icon: 'warning',
      });
    } else {
      let tmp = {
        id: formData.account_code,
        date_from: formData.date_from,
        date_to: formData.date_to,
        tab: 'receivables',
      };
      toast.loading('Exporting data in progress, Please wait...', {
        position: toast.POSITION.TOP_RIGHT,
      });
      requestReceivablesExport(tmp)
        .then((res) => {
          if (res.data.response.code === '00000') {
            window.location.href = res.data.response.result;
            toast.dismiss();
          } else {
            Swal.fire({
              title: 'Error',
              text: res,
              icon: 'error',
            });
          }
        })
        .catch((err) => {});
    }
  };

  const handlegeneralLedgerExport = () => {
    let tmp = {
      account_code: formData.account_code,
      date_from: formData.date_from,
      date_to: formData.date_to,
    };

    toast.loading('Exporting data in progress, Please wait...', {
      position: toast.POSITION.TOP_RIGHT,
    });

    console.log(tmp);
    requestGeneralLedgerExport(tmp)
      .then((res) => {
        console.log({ res });
        if (res.data.code === '00000') {
          console.log(res);
          window.location.href = res.data.result;
          toast.dismiss();
        } else {
          Swal.fire({
            title: 'Error',
            text: res,
            icon: 'error',
          });
        }
      })
      .catch((err) => {});
  };

  return (
    <GeneralLedgerReportScreen
      //throw parameter or props.
      formData={formData}
      dateChange={getDate}
      onSearch={searchLedger}
      handleSearch={handleSearch}
      generalLedgerData={generalLedgerData}
      selectedTab={'general_ledger'}
      handleSelectedTab={handleSelectedTab}
      handleChange={handleChange}
      loading={loading}
      totalRow={totalRow}
      handleDataTableChange={handleDataTableChange}
      handleFilterModelChange={handleFilterModelChange}
      handleTableChange={handleTableChange}
      reloadTable={reloadTable}
      handleExport={OnHandleExport}
      columnRunning={columnRunning}
      onHandlegeneralLedgerExport={handlegeneralLedgerExport}
    />
  );
};

export default General;
