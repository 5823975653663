import { useEffect, useState, useRef } from 'react';
import { Row, Col, Form, Card, Modal, Button } from 'react-bootstrap';
import { numberFormatCurrency } from '../../utils/helper';
import MultipleFileUploadPreview from '../MultipleFileUploadPreview';
import { hasPermission } from '../PermissionsGate';

import PrimaryDataTable from '../PrimaryDataTable';
import PrimaryLoader from '../PrimaryLoader';

import dataURLtoBlob from 'blueimp-canvas-to-blob';
import SignaturePad from 'react-signature-pad-wrapper';

import { productColumn } from './const';
import Swal from 'sweetalert2';

const CustomerTransactionModal = ({
  srpfDetails,
  showTransactionModal,
  handleCloseTransactionModal,
  showApprovalButton,
  showApprovedButton,
  showDeclinedButton,
  handleExportPDFTransaction,
  handleApprovalTransaction,
  handleApproveTransaction,
  handleDeclinedTransaction,
  handleSplitBatching,
  showSplitButton,
  handleFileChange,
  handleUploadFile,
  pageLoader = false,
  attachmentButton,
  showExportPDF,
}) => {
  const [showPad, setShowPad] = useState(true);
  const signatureRef = useRef({
    isEmpty: () => true,
  });

  useEffect(() => {
    if (showTransactionModal) {
      setTimeout(() => {
        setShowPad(false);
        setTimeout(() => {
          setShowPad(true);
        }, 0);
      }, 0);
    }
  }, [showTransactionModal]);

  const Acknowledgement = () => {
    return srpfDetails.documents
      .filter((e) => e.img_type === 'SIG')
      .map((value, key) => (
        <div className="row" key={key}>
          <div className="col-sm-12" key={key}>
            <img
              key={key}
              alt="Signature"
              src={value.document_info?.processed_path}
              height="100"
              width="400"
              className="border"
            />
          </div>
        </div>
      ));
  };

  const req = srpfDetails.documents.filter((e) => e.img_type === 'REQ SIG');

  const RequestorSignature = () => {
    const req = srpfDetails.documents.filter((e) => e.img_type === 'REQ SIG');

    if (req.length > 0 && req[0].document_info?.processed_path !== null) {
      return (
        <div className="row">
          <div className="col-sm-12" key={1}>
            <img
              key={1}
              alt="Signature"
              src={req[0].document_info?.processed_path}
              height="100"
              width="400"
              className="border"
            />
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <div
            style={{
              border: '1px solid #ced4da',
              borderRadius: '0.25rem',
              width: '100%',
              height: '130px',
              marginBottom: '5px',
            }}
          >
            {showPad && (
              <SignaturePad
                ref={signatureRef}
                height={130}
                options={{
                  minWidth: 2,
                  maxHeight: 100,
                  penColor: 'rgb(0, 0, 0)',
                }}
                style={{ backGroundColor: '#000' }}
              />
            )}
          </div>
          <div className="text-right">
            <button
              type="button"
              className="btn btn-info btn-sm"
              onClick={showSignaturePad}
            >
              Clear Signature
            </button>
          </div>
        </div>
      );
    }
  };

  const showSignaturePad = () => {
    setShowPad(false);
    setTimeout(() => {
      setShowPad(true);
    }, 0);
  };

  const onAddAttachment = () => {
    if (srpfDetails.documents_new.length > 0) {
      Swal.fire({
        icon: 'question',
        title: 'Are you sure you want to upload?',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        cancelButtonColor: '#d33',
      }).then((result) => {
        if (result.isConfirmed) {
          handleUploadFile({
            attachments: srpfDetails.documents_new,
            srpf_no: srpfDetails.srpf_no,
          });
        }
      });
    } else {
      Swal.fire({
        icon: 'warning',
        title: 'Please select a file to upload.',
      });
    }
  };

  const getSignature = () => {
    if (!signatureRef.current.isEmpty()) {
      return dataURLtoBlob(signatureRef.current.toDataURL('image/png'));
    }

    return null;
  };

  return (
    <div>
      <Modal
        show={showTransactionModal}
        onHide={handleCloseTransactionModal}
        size="xl"
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-width"
      >
        <PrimaryLoader visible={pageLoader} />
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Card>
            <Card.Header>
              <strong>
                {srpfDetails.get_customer_info !== null
                  ? srpfDetails.get_customer_info.customer_name
                  : ''}
              </strong>
            </Card.Header>
            <Card.Body>
              <Row className="mb-2">
                <Col lg="6" style={{ fontSize: '14px' }}>
                  <Form.Group as={Row} className="mb-1">
                    <Form.Label column sm="3">
                      SRPF No.
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        plaintext
                        readOnly
                        value={srpfDetails.srpf_no}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-1">
                    <Form.Label column sm="3">
                      Account Name
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        plaintext
                        readOnly
                        value={
                          srpfDetails.get_customer_info !== null
                            ? srpfDetails.get_customer_info.customer_name
                            : ''
                        }
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-1">
                    <Form.Label column sm="3">
                      Customer Code
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        plaintext
                        readOnly
                        value={
                          srpfDetails.get_customer_info !== null
                            ? srpfDetails.get_customer_info.customer_code
                            : ''
                        }
                      />
                    </Col>
                  </Form.Group>
                </Col>
                <Col lg="6" style={{ fontSize: '14px' }}>
                  <Form.Group as={Row} className="mb-1">
                    <Form.Label column sm="3">
                      SRPF Date
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        plaintext
                        readOnly
                        value={srpfDetails.srpf_date}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-1">
                    <Form.Label column sm="3">
                      SRPF Approved Date
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        plaintext
                        readOnly
                        value={srpfDetails.posting_date}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-1">
                    <Form.Label column sm="3">
                      RR Date
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        plaintext
                        readOnly
                        value={srpfDetails.rr_date}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-1">
                    <Form.Label column sm="3">
                      RR Approved Date
                    </Form.Label>
                    <Col sm="9">
                      {srpfDetails.rr_approved_date != null
                        ? srpfDetails.rr_approved_date
                            .split(',')
                            .map((value) => <div>{value}</div>)
                        : ''}
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-1">
                    <Form.Label column sm="3">
                      Reason
                    </Form.Label>
                    <Col sm="9">
                      {srpfDetails.reject_reason.map((row) => (
                        <>
                          {row.reject_reason}
                          <br />
                        </>
                      ))}
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-1">
                    <Form.Label column sm="3">
                      Type of Return
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        plaintext
                        readOnly
                        value={
                          srpfDetails.srpf_type === 'SRPF'
                            ? 'Customer Returns'
                            : 'Return to Supplier'
                        }
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col lg="12">
                  <PrimaryDataTable
                    autoHeight
                    rows={srpfDetails.products}
                    columns={productColumn({
                      numberFormatCurrency,
                    })}
                    getRowId={(row) =>
                      showApprovedButton || showApprovalButton
                        ? row.srpf_dtl_approval_id
                        : row.srpf_dtl_id
                    }
                  />
                </Col>
              </Row>
              {/*  */}
              <Row className="mb-2">
                <Col sm="6" style={{ fontSize: '14px' }}>
                  <Form.Group as={Row} className="mb-1">
                    <Form.Label column sm="4">
                      Preferred Disposition
                    </Form.Label>
                    <Col sm="6">
                      <Form.Control
                        plaintext
                        readOnly
                        value={
                          srpfDetails.disposition === 'ADV'
                            ? 'Apply to Advances'
                            : 'Apply to Aging'
                        }
                      />
                    </Col>
                  </Form.Group>
                  {/*  */}
                  {srpfDetails.disposition_reference.map((value, key) => (
                    <Form.Group as={Row} className="mb-1">
                      <div className="col-sm-4 text-right">
                        {value.dr_no} {value.si_no} -{' '}
                      </div>
                      <div className="col-sm-6 text-left">
                        {numberFormatCurrency(value.applied_amount)}
                      </div>
                    </Form.Group>
                  ))}
                  {/*  */}
                  {srpfDetails.disposition_reference.length > 0 && (
                    <Form.Group as={Row} className="mb-1">
                      <div className="col-sm-4 text-right font-weight-bold">
                        Total Aging:
                      </div>
                      <div className="col-sm-6 text-left font-weight-bold">
                        {numberFormatCurrency(
                          srpfDetails.disposition_reference.reduce(
                            (total, row) => {
                              return total + parseFloat(row.applied_amount);
                            },
                            0,
                          ),
                        )}
                      </div>
                    </Form.Group>
                  )}
                  {/*  */}
                  <Form.Group as={Row} className="mb-1">
                    <Form.Label column sm="4">
                      Remarks
                    </Form.Label>
                    <Col sm="6">
                      <Form.Control
                        as="textarea"
                        plaintext
                        readOnly
                        value={srpfDetails.remarks ?? ''}
                      />
                    </Col>
                  </Form.Group>
                  {/*  */}
                  <Form.Group as={Row} className="mb-1">
                    <Form.Label column sm="4">
                      Customer Acknowledgement
                    </Form.Label>
                    <Col sm="6">
                      <Acknowledgement />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-1">
                    <Form.Label column sm="4">
                      Requestor Signature DAM/AS
                    </Form.Label>
                    <Col sm="6">
                      {/* <RequestorSignature /> */}
                      {req.length > 0 &&
                      req[0].document_info.processed_path !== null ? (
                        <div className="row">
                          <div className="col-sm-12" key={1}>
                            <img
                              key={1}
                              alt="Signature"
                              src={req[0].document_info?.processed_path}
                              height="100"
                              width="400"
                              className="border"
                            />
                          </div>
                        </div>
                      ) : (
                        <div>
                          <div
                            style={{
                              border: '1px solid #ced4da',
                              borderRadius: '0.25rem',
                              width: '100%',
                              height: '130px',
                              marginBottom: '5px',
                            }}
                          >
                            {showPad && (
                              <SignaturePad
                                ref={signatureRef}
                                height={130}
                                options={{
                                  minWidth: 2,
                                  maxHeight: 100,
                                  penColor: 'rgb(0, 0, 0)',
                                }}
                                style={{ backGroundColor: '#000' }}
                              />
                            )}
                          </div>
                          <div className="text-right">
                            <button
                              type="button"
                              className="btn btn-info btn-sm"
                              onClick={showSignaturePad}
                            >
                              Clear Signature
                            </button>
                          </div>
                        </div>
                      )}
                    </Col>
                  </Form.Group>
                  {/*  */}
                  <Form.Group as={Row} className="mb-1">
                    <Form.Label column sm="4">
                      Attachments
                    </Form.Label>
                    {/*  */}
                    <div className="col-sm-6">
                      {/* <Attachments /> */}
                      <div>
                        <MultipleFileUploadPreview
                          newAttachments={srpfDetails.documents_new}
                          oldAttachments={srpfDetails.documents
                            .filter((e) => e.img_type === 'OTH')
                            .map((row) => ({
                              path: row.document_info.processed_path,
                              name: row.document_info.file_name,
                            }))}
                          handleRemoveFile={[]}
                          handleFileUpload={handleFileChange}
                          disabled={attachmentButton}
                          resetKey={srpfDetails.reset_attachment}
                        />
                      </div>
                      <div className="mt-1 text-right">
                        <button
                          type="button"
                          className="btn btn-info btn-sm"
                          onClick={onAddAttachment}
                        >
                          Upload Attachment
                        </button>
                      </div>
                    </div>
                  </Form.Group>
                </Col>
                {/*  */}
                <Col sm="6" style={{ fontSize: '14px' }}>
                  <div className="form-group row">
                    <label
                      className="col-sm-6 col-form-label text-right"
                      style={{ fontSize: '12px' }}
                    >
                      Total Amount
                    </label>
                    <div className="col-sm-6">
                      <input
                        type="text"
                        name="total_amount"
                        className="form-control form-control-sm text-right"
                        value={numberFormatCurrency(
                          parseFloat(srpfDetails.total_amount),
                        )}
                        readOnly
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label
                      className="col-sm-6 col-form-label text-right"
                      style={{ fontSize: '12px' }}
                    >
                      Total 60/40 Near Expiry
                    </label>
                    <div className="col-sm-6">
                      <input
                        type="text"
                        className="form-control form-control-sm text-right"
                        value={numberFormatCurrency(
                          srpfDetails.products
                            .filter((e) => e.reason === '60-40 Near Expiry')
                            .reduce((total, row) => {
                              return total + parseFloat(row.total_cost) * 0.4;
                            }, 0),
                        )}
                        readOnly
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label
                      className="col-sm-6 col-form-label text-right"
                      style={{ fontSize: '12px' }}
                    >
                      Amount Applicable (JPI)
                    </label>
                    <div className="col-sm-6">
                      <input
                        type="text"
                        className="form-control form-control-sm text-right"
                        value={numberFormatCurrency(
                          parseFloat(srpfDetails.applicable_amount),
                        )}
                        readOnly
                      />
                    </div>
                  </div>

                  <div className="form-group row d-none">
                    <label
                      className="col-sm-6 col-form-label text-right"
                      style={{ fontSize: '12px' }}
                    >
                      Total Applied Amt.
                    </label>
                    <div className="col-sm-6">
                      <input
                        type="text"
                        className="form-control form-control-sm text-right"
                        value={numberFormatCurrency(
                          srpfDetails.disposition_reference.reduce(
                            (total, row) => {
                              return total + parseFloat(row.applied_amount);
                            },
                            0,
                          ),
                        )}
                        readOnly
                      />
                    </div>
                  </div>

                  <div className="form-group row d-none">
                    <label
                      className="col-sm-6 col-form-label text-right"
                      style={{ fontSize: '12px' }}
                    >
                      Total Balance
                    </label>
                    <div className="col-sm-6">
                      <input
                        type="text"
                        className="form-control form-control-sm text-right"
                        value={numberFormatCurrency(
                          parseFloat(srpfDetails.applicable_amount) -
                            srpfDetails.disposition_reference.reduce(
                              (total, row) => {
                                return total + parseFloat(row.applied_amount);
                              },
                              0,
                            ),
                        )}
                        readOnly
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col lg="6" style={{ fontSize: '14px' }}>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      Encoded By:
                    </Form.Label>
                    <Col sm="6">
                      <Form.Control
                        size="sm"
                        plaintext
                        readOnly
                        value={srpfDetails?.get_encoded_by?.employee_name}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      Approved By:
                    </Form.Label>
                    {/*  */}
                    <div className="col-sm-6">
                      <Form.Control
                        size="sm"
                        plaintext
                        readOnly
                        value={srpfDetails?.get_approved_by?.employee_name}
                      />
                    </div>
                  </Form.Group>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Modal.Body>
        <Modal.Footer>
          {/* {hasPermission(['approval_Srpf']) &&
            showApprovalButton &&
            srpfDetails.status !== '1' && (
              <> */}
          {showExportPDF?.status ? (
            <Button
              className="btn btn-primary"
              onClick={() => {
                handleExportPDFTransaction(
                  showExportPDF.location === 'approved'
                    ? srpfDetails.srpf_id
                    : srpfDetails.srpf_approval_id,
                  showExportPDF.location,
                );
              }}
            >
              Export PDF
            </Button>
          ) : (
            ''
          )}
          {/* </>
            )} */}

          {hasPermission(['approval_Srpf']) &&
            showApprovalButton &&
            srpfDetails.status !== '1' && (
              <>
                <Button
                  className="btn btn-success"
                  onClick={() => {
                    handleApprovalTransaction(
                      srpfDetails.srpf_approval_id,
                      getSignature(),
                    );
                  }}
                >
                  For Approval
                </Button>
              </>
            )}

          {hasPermission(['approve_Srpf']) &&
            showApprovedButton &&
            srpfDetails.status !== '1' && (
              <>
                <Button
                  className="btn btn-success"
                  onClick={() => {
                    handleApproveTransaction(srpfDetails.srpf_approval_id);
                  }}
                >
                  Approve
                </Button>
              </>
            )}

          {hasPermission(['decline_Srpf']) && showDeclinedButton && (
            <Button
              className="btn btn-danger"
              onClick={() => {
                handleDeclinedTransaction(srpfDetails.srpf_no);
              }}
            >
              Declined
            </Button>
          )}

          {hasPermission(['split_srpf']) && showSplitButton && (
            <Button
              className="btn btn-warning"
              onClick={() => {
                handleSplitBatching(srpfDetails.srpf_no);
              }}
            >
              RTM for Split Batching
            </Button>
          )}

          <Button variant="secondary" onClick={handleCloseTransactionModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CustomerTransactionModal;
