import { lazy } from 'react';

const UndefinedDepositSubLedgerReport = lazy(() =>
  import(
    '../pages/UndefinedDepositSubLedgerReport/UndefinedDepositSubLedgerReport'
  ),
);

const undefinedDepositSubLedgerReportRoute = {
  public: [],
  auth: [
    {
      name: 'undefined_deposit_subLedger_report',
      path: '/finance-and-accounting/undefined/deposit/subledger',
      component: UndefinedDepositSubLedgerReport,
    },
  ],
};

export default undefinedDepositSubLedgerReportRoute;
