import { lazy } from 'react';

const CreditMemoMonitoringReport = lazy(() =>
  import('../pages/CreditMemoMonitoringReport/index.js').then((module) => ({
    default: module.CreditMemoMonitoringReport,
  })),
);

const creditMemoMonitoringReportRoute = {
  auth: [
    {
      name: 'cwt_report_and_updating_module',
      path: '/finance-and-accounting/cwt-report-and-updating-module',
      component: CreditMemoMonitoringReport,
    },
  ],
};

export default creditMemoMonitoringReportRoute;
