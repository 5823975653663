import DefaultLayout from '../../../components/Layouts/DefaultLayout';
import PrimaryDataTable from '../../../components/PrimaryDataTable';
import PrimaryDatePicker from '../../../components/PrimaryDatePicker';
import { columns } from '../const';
import { Button, Col, Form, Row, Tab, Tabs } from 'react-bootstrap';
import {
  AlertDefaultError,
  PermissionsGate,
} from '../../../components/PermissionsGate';

const EWTScreen = ({
  tableData,
  filter,
  onSearch,
  onExport,
  isLoading,
  onFilterChange,
  onTableFilter,
  onTableChange,
}) => {
  return (
    <PermissionsGate
      scopes={['get_ewt_payable_report']}
      RenderError={AlertDefaultError}
    >
      <DefaultLayout title="EWT Payable Report">
        <Tabs id="controlled-tab-example" className="default-tab">
          <Tab
            className="default-tab-content"
            eventKey="general"
            title="General"
          >
            {/* <Filter onFilter={onFilter} onExport={onExport} /> */}
            <PermissionsGate scopes={['get_ewt_all']}>
              <Row>
                <Col lg={8}>
                  <Row>
                    <Col>
                      <Form.Group className="mb-3">
                        <Form.Label>Date From</Form.Label>
                        <PrimaryDatePicker
                          placeholder="Date From"
                          type="text"
                          name={'date_from'}
                          datePickerFormat={'yyyy-MM-dd'}
                          datePickerFormatString={'YYYY-MM-DD'}
                          value={filter.date_from}
                          onChange={(date) =>
                            onFilterChange({
                              target: { name: 'date_from', value: date },
                            })
                          }
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-3">
                        <Form.Label>Date To</Form.Label>
                        <PrimaryDatePicker
                          placeholder="Date To"
                          name={'date_to'}
                          datePickerFormat={'yyyy-MM-dd'}
                          datePickerFormatString={'YYYY-MM-DD'}
                          value={filter.date_to}
                          type="text"
                          onChange={(date) =>
                            onFilterChange({
                              target: { name: 'date_to', value: date },
                            })
                          }
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-3">
                        <Form.Label>&nbsp;</Form.Label>
                        <Button variant="primary" onClick={onSearch}>
                          Search
                        </Button>
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>

                <Col className="text-right">
                  <Form.Group className="mb-3">
                    <Form.Label>&nbsp;</Form.Label>
                    <Button variant="info" onClick={onExport}>
                      Export
                    </Button>
                  </Form.Group>
                </Col>
              </Row>
              <PrimaryDataTable
                getRowId={(row) => row.id}
                rows={tableData.result}
                columns={columns}
                rowCount={tableData.total_rows}
                loading={isLoading}
                onFilterModelChange={onTableFilter}
                filterMode="server"
                mode="server"
                onChange={onTableChange}
              />
            </PermissionsGate>
          </Tab>
        </Tabs>
      </DefaultLayout>
    </PermissionsGate>
  );
};

export default EWTScreen;
