// @flow
const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN';
const AUTHENTICATE_USER = 'AUTHENTICATE_USER';
const SET_PROFILE_DATA = 'SET_PROFILE_DATA';
const SET_LOGGING_OUT = 'SET_LOGGING_OUT';
const SET_PERMISSIONS = 'SET_PERMISSIONS';

export function updateUserToken(accessToken) {
  return {
    type: SET_ACCESS_TOKEN,
    accessToken,
  };
}

export function updateUserPermissions(permissions) {
  return {
    type: SET_PERMISSIONS,
    permissions,
  };
}

export function authUser(data) {
  return {
    type: AUTHENTICATE_USER,
    data,
  };
}

export function updateProfileData(data) {
  return {
    type: SET_PROFILE_DATA,
    data,
  };
}

export function setLoggingOut() {
  return {
    type: SET_LOGGING_OUT,
  };
}

const initialState = {
  isAuthed: false,
  isLoggingOut: false,
  profileData: {},
  accessToken: null,
  permissions: [],
};

export default function auth(state = initialState, action) {
  switch (action.type) {
    case SET_ACCESS_TOKEN:
      return {
        ...state,
        isLoggingOut: false,
        accessToken: action.accessToken,
      };
    case AUTHENTICATE_USER:
      return {
        ...state,
        profileData: action.data.user,
        isAuthed: true,
        accessToken: action.data.accessToken,
        permissions: action.data.permissions,
      };
    case SET_PROFILE_DATA:
      return {
        ...state,
        profileData: {
          ...state.profileData,
          ...action.data.user,
        },
      };
    case SET_PERMISSIONS:
      console.log({ state, action, set_permissions: 1 });
      return {
        ...state,
        permissions: action.permissions,
      };
    case SET_LOGGING_OUT:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
}
