import { lazy } from 'react';

const inventoryReceivedNotYetInvoiced = lazy(() =>
  import('../pages/InventoryReceivedNotYetInvoiced'),
); // insert the source page

const inventoryReceivedNotYetInvoicedRoute = {
  public: [],
  auth: [
    {
      name: 'ir_not_yet_invoice',
      path: '/finance-and-accounting/ir-not-yet-invoice',
      component: inventoryReceivedNotYetInvoiced,
    },
  ],
};

export default inventoryReceivedNotYetInvoicedRoute;
