import { lazy } from 'react';

const InputVat = lazy(() =>
  import('../pages/InputVat/index.js').then((module) => ({
    default: module.InputVat,
  })),
);

const inputVatRoute = {
  public: [],
  auth: [
    {
      name: 'input_vat',
      path: '/finance-and-accounting/input-vat',
      component: InputVat,
    },
  ],
};

export default inputVatRoute;
