import { lazy } from 'react';

const AdvancesFromCustomer = lazy(() =>
  import('../pages/AdvancesFromCustomer/index'),
);
const AdvancesSupplier = lazy(() =>
  import('../pages/AdvancesSupplierSubledger/index'),
);

// const CreditMemoMonitoringReport = lazy(() =>
//   import('../pages/CreditMemoMonitoringReport/index.js').then((module) => ({
//     default: module.CreditMemoMonitoringReport,
//   })),
// );

const creditMemoMonitoringReportRoute = {
  auth: [
    // {
    //   name: 'memo_credit_monitoring_report',
    //   path: '/finance-and-accounting/memo-credit/monitoring-report',
    //   component: CreditMemoMonitoringReport,
    // },
    {
      name: 'advances_from_customer',
      path: '/finance-and-accounting/advances-from-customers',
      component: AdvancesFromCustomer,
    },
    {
      name: 'advances_to_suppliers',
      path: '/finance-and-accounting/advances-to-suppliers',
      component: AdvancesSupplier,
    },
  ],
};

export default creditMemoMonitoringReportRoute;
