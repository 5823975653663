import { Fragment, useEffect, useState } from 'react';

import PrimaryDataTable from '../../../../components/PrimaryDataTable';
// api
import { getCustomerSummaryTransactionHistory } from '../../../../api/collections';

// data and consts
import { transactionHistoryDetailsColumns } from './const';
import CheckDetailModal from './CheckDetailModal';

import Button, { ButtonProps } from '@mui/material/Button';
import { createSvgIcon } from '@mui/material/utils';

import {
  DataGridPremium,
  GridCsvExportOptions,
  GridCsvGetRowsToExportParams,
  gridRowTreeSelector,
  GridToolbarContainer,
  gridFilteredSortedRowIdsSelector,
  useGridApiContext,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid-pro';
import { generateUUID } from '../../../../utils/helper';

const getRowsWithGroups = ({ apiRef }) =>
  gridFilteredSortedRowIdsSelector(apiRef);

const getRowsWithoutGroups = ({ apiRef }) => {
  const rows = gridFilteredSortedRowIdsSelector(apiRef);
  const tree = gridRowTreeSelector(apiRef);
  // return rows.filter((rowId) => !tree[rowId].isAutoGenerated);
  return rows.filter((rowId) => tree[rowId].parent !== null);
};

const ExportIcon = createSvgIcon(
  <path d="M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2z" />,
  'SaveAlt',
);

const CustomToolbar = () => {
  const apiRef = useGridApiContext();

  const handleExport = (options) => apiRef.current.exportDataAsCsv(options);

  const buttonBaseProps = {
    color: 'primary',
    size: 'small',
    startIcon: <ExportIcon />,
  };

  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <Button
        {...buttonBaseProps}
        onClick={() => handleExport({ getRowsToExport: getRowsWithoutGroups })}
      >
        EXPORT
      </Button>
    </GridToolbarContainer>
  );
};

const TransactionHistory = ({ customer_code }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  // detail
  const [detailModal, setDetailModal] = useState({ toggle: false, data: [] });

  useEffect(() => loadData(customer_code), [customer_code]);

  const loadData = (customerCode) => {
    if (customerCode === '') return;

    setLoading(true);
    setData([]);

    getCustomerSummaryTransactionHistory(customerCode).then((res) => {
      setLoading(false);
      const { code, result } = res.data.response;

      if (code === '00000') {
        let formattedValue = [];
        let uniqueID = 0;

        result.sales_invoices
          .map((e) => ({ ...e, unique: uniqueID++ }))
          .forEach((row, key) => {
            formattedValue.push({
              hierarchy: ['SI NO.: ' + row.si_number + '-' + row.unique],
              ...row,
              amount: row.total_ar,
              is_parent: 1,
              transaction_type: undefined,
            });

            row.si_details.forEach((col) => {
              let transType = '';

              if (col.transaction_type === 'sales') {
                transType = 'SALES';

                formattedValue.push({
                  hierarchy: [
                    'SI NO.: ' + row.si_number + '-' + row.unique,
                    col.transaction_date +
                      ' TRANSACTION TYPE: ' +
                      transType +
                      '-' +
                      Math.floor(Math.random() * 100) +
                      ' ID: ' +
                      generateUUID(),
                  ],
                  ...col,
                  date: col.transaction_date,
                  amount_due: col.amount_due,
                });
              } else if (col.transaction_type === 'cm_collection') {
                transType = 'COLLECTION';

                col.check_details.forEach((check_details) => {
                  formattedValue.push({
                    hierarchy: [
                      'SI NO.: ' + row.si_number + '-' + row.unique,
                      col.transaction_date +
                        ' TRANSACTION TYPE: ' +
                        transType +
                        '-' +
                        Math.floor(Math.random() * 100) +
                        ' ID: ' +
                        generateUUID(),
                    ],
                    ...col,
                    date: col.transaction_date,
                    amount_due: col.amount_due,
                    check_no: check_details.check_no,
                    check_date: check_details.check_date,
                    bank_name: check_details.bank,
                    received_date: check_details.received_date,
                  });
                });
              } else if (col.transaction_type === 'cash_reversal') {
                transType = 'CASH REVERSAL';

                col.check_details.forEach((check_details) => {
                  formattedValue.push({
                    hierarchy: [
                      'SI NO.: ' + row.si_number + '-' + row.unique,
                      col.transaction_date +
                        ' TRANSACTION TYPE: ' +
                        transType +
                        '-' +
                        Math.floor(Math.random() * 100) +
                        ' ID: ' +
                        generateUUID(),
                    ],
                    ...col,
                    date: col.transaction_date,
                    amount_due: col.amount_due,
                    check_no: check_details.check_no,
                    check_date: check_details.check_date,
                    bank_name: check_details.bank,
                    received_date: check_details.received_date,
                  });
                });
              } else if (col.transaction_type === 'cm_deductions') {
                transType = 'CM';

                formattedValue.push({
                  hierarchy: [
                    'SI NO.: ' + row.si_number + '-' + row.unique,
                    col.transaction_date +
                      ' TRANSACTION TYPE: ' +
                      transType +
                      '-' +
                      Math.floor(Math.random() * 100) +
                      ' ID: ' +
                      generateUUID(),
                  ],
                  ...col,
                  date: col.transaction_date,
                  amount_due: col.amount_due,
                });
              } else if (col.transaction_type === 'PENDING COLLECTION') {
                transType = 'CM';

                formattedValue.push({
                  hierarchy: [
                    'SI NO.: ' + row.si_number + '-' + row.unique,
                    col.transaction_date +
                      ' TRANSACTION TYPE: ' +
                      transType +
                      '-' +
                      Math.floor(Math.random() * 100) +
                      ' ID: ' +
                      generateUUID(),
                  ],
                  ...col,
                  date: col.transaction_date,
                  pdc_not_clear: col.pdc_not_clear,
                });
              } else if (
                col.transaction_type === 'jv_debit' ||
                col.transaction_type === 'jv_credit'
              ) {
                transType = 'JV';

                formattedValue.push({
                  hierarchy: [
                    'SI NO.: ' + row.si_number + '-' + row.unique,
                    col.transaction_date +
                      ' TRANSACTION TYPE: ' +
                      transType +
                      '-' +
                      Math.floor(Math.random() * 100) +
                      ' ID: ' +
                      generateUUID(),
                  ],
                  ...col,
                  date: col.transaction_date,
                  amount_due: col.amount_due,
                });
              } else if (col.transaction_type === 'RETURNED COLLECTION') {
                transType = 'CM';

                formattedValue.push({
                  hierarchy: [
                    'SI NO.: ' + row.si_number + '-' + row.unique,
                    col.transaction_date +
                      ' TRANSACTION TYPE: ' +
                      transType +
                      '-' +
                      Math.floor(Math.random() * 100) +
                      ' ID: ' +
                      generateUUID(),
                  ],
                  ...col,
                  date: col.transaction_date,
                  returned_amount: col.returned_amount,
                });
              }

              // if (Array.isArray(col.check_details)) {
              //   col.check_details.forEach((chk) => {
              //     formattedValue.push({
              //       hierarchy: [
              //         "SI NO.: " + row.si_number + "-" + row.unique,
              //         col.transaction_date +
              //           " TRANSACTION TYPE: " +
              //           transType +
              //           "OR NO.: " +
              //           chk.collection_payment_details_id +
              //           "-" +
              //           Math.floor(Math.random() * 100),
              //       ],
              //       ...chk,
              //       transaction_type: col.transaction_type,
              //       si_number: row.si_number,
              //       dr_number: row.dr_number,
              //       reference: col.reference,
              //     });
              //   });
              // }
            });
          });

        //console.log(formattedValue.map((e) => ({ ...e, id: uniqueID++ })));
        setData(formattedValue.map((e) => ({ ...e, id: uniqueID++ })));
      }
    });
  };

  const handleShowCheckDetails = (row) =>
    setDetailModal({ toggle: true, data: row.check_details });

  const columns = transactionHistoryDetailsColumns({
    handleShowCheckDetails: handleShowCheckDetails,
  });

  const getTreeDataPath = (row) => row.hierarchy;

  const groupingColDef = {
    headerName: 'SI Number',
    valueGetter: ({ row }) => row.si_number,
  };

  return (
    <Fragment>
      <PrimaryDataTable
        key={customer_code}
        treeData
        rows={data}
        columns={columns}
        disableColumnSelector={true}
        disableSelectionOnClick
        loading={loading}
        getTreeDataPath={getTreeDataPath}
        groupingColDef={groupingColDef}
        defaultGroupingExpansionDepth={-1}
        getCellClassName={({ row }) =>
          row.hasOwnProperty('is_parent') ? 'bg-orange-lt' : ''
        }
        components={{ Toolbar: CustomToolbar }}
      />

      <CheckDetailModal
        toggle={detailModal.toggle}
        closeModal={() => setDetailModal({ toggle: false, data: [] })}
        data={detailModal.data}
        type={'transaction_history'}
      />
    </Fragment>
  );
};

export default TransactionHistory;
