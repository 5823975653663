import { lazy } from 'react';

const CashAdvancedAging = lazy(() => import('../pages/CashAdvancedAging'));

const cashAdvancedAgingRoute = {
  public: [],
  auth: [
    {
      name: 'cash_advanced_aging',
      path: '/finance-and-accounting/cash-advanced-aging',
      component: CashAdvancedAging,
    },
  ],
};

export default cashAdvancedAgingRoute;
