import Box from '@material-ui/core/Box';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { Fragment, useState } from 'react';
import useNavActive from '../Navbar/useNavActive';
import CoaPermissions from '../Permissions/coa';
import Permission from '../Permissions/Permission';
import { PermissionsGate } from '../PermissionsGate';
import Roles from '../Roles/Roles';
import User from '../User/User';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 500,
  },
}));

const UserControl = () => {
  useNavActive(['master_databases', 'user_database']);
  const [showTab, setTab] = useState('tab1');
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [showUsers, setShowUsers] = useState(true);
  const [showRoles, setShowRoles] = useState(false);
  const [showPermissions, setShowPermissions] = useState(false);
  const [showCoaPermissions, setCoaPermissions] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Fragment>
      <PermissionsGate scopes={['get_all_users']}>
        <div className="page-wrapper">
          <div className="container-fluid">
            <div className="page-header d-print-none">
              <div className="row align-items-center">
                <div className="col">
                  <h2 className="page-title">Users and Roles</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="card-tabs mt-2">
            <ul className="nav nav-tabs bg-header">
              <li className="nav-item">
                <a
                  href="#tab-top-1"
                  className={showTab == 'tab1' ? 'nav-link active' : 'nav-link'}
                  data-toggle="tab"
                  onClick={() => {
                    setTab('tab1');
                    setShowUsers(true);
                    setShowRoles(false);
                    setShowPermissions(false);
                    setCoaPermissions(false);
                  }}
                >
                  User Management
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="#tab-top-2"
                  className={showTab == 'tab2' ? 'nav-link active' : 'nav-link'}
                  data-toggle="tab"
                  onClick={() => {
                    setTab('tab2');
                    setShowUsers(false);
                    setShowRoles(true);
                    setShowPermissions(false);
                    setCoaPermissions(false);
                  }}
                >
                  Roles Management
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="#tab-top-3"
                  className={showTab == 'tab3' ? 'nav-link active' : 'nav-link'}
                  data-toggle="tab"
                  onClick={() => {
                    setTab('tab3');
                    setShowUsers(false);
                    setShowRoles(false);
                    setShowPermissions(true);
                    setCoaPermissions(false);
                  }}
                >
                  Permissions Management
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="#tab-top-4"
                  className={showTab == 'tab4' ? 'nav-link active' : 'nav-link'}
                  data-toggle="tab"
                  onClick={() => {
                    setTab('tab4');
                    setShowUsers(false);
                    setShowRoles(false);
                    setShowPermissions(false);
                    setCoaPermissions(true);
                  }}
                >
                  PV/JV Accounts permission
                </a>
              </li>
            </ul>
            <div className="tab-content">
              <div
                id="tab-top-1"
                className={
                  showTab === 'tab1'
                    ? 'card tab-pane active show'
                    : 'card tab-pane'
                }
              >
                <div className="card-body">{showUsers ? <User /> : ''}</div>
              </div>
              <div
                id="tab-top-2"
                className={
                  showTab === 'tab2'
                    ? 'card tab-pane active show'
                    : 'card tab-pane'
                }
              >
                <div className="card-body">{showRoles ? <Roles /> : ''}</div>
              </div>
              <div
                id="tab-top-3"
                className={
                  showTab === 'tab3'
                    ? 'card tab-pane active show'
                    : 'card tab-pane'
                }
              >
                <div className="card-body">
                  {showPermissions ? <Permission /> : ''}
                </div>
              </div>

              <div
                id="tab-top-4"
                className={
                  showTab === 'tab4'
                    ? 'card tab-pane active show'
                    : 'card tab-pane'
                }
              >
                <div className="card-body">
                  {showCoaPermissions ? <CoaPermissions /> : ''}
                </div>
              </div>
            </div>
          </div>
        </div>
      </PermissionsGate>
    </Fragment>
  );
};

export default UserControl;
