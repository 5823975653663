import { lazy } from 'react';

const UDDv2 = lazy(() =>
  import('../pages/UDDv2/index.js').then((module) => ({
    default: module.UDDv2,
  })),
);

const undefinedDepositSubledgerV2Route = {
  public: [],
  auth: [
    {
      name: 'undefined_deposit_subledger_v2',
      path: '/finance-and-accounting/undefined-deposit-subledger-v2',
      component: UDDv2,
    },
  ],
};

export default undefinedDepositSubledgerV2Route;
