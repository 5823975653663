import {} from '../PermissionsGate';
import { creditCollectionIcon, inventoryIcon, masterDBIcon } from './const';
import {
  advancesFromCustomerPermission,
  advancesToSuppliersPermission,
  advancesToVendorPermission,
  assetExpenseSubledgerPermission,
  assetManagementListingsPermission,
  assetManagementPurchaseOrderNSPermission,
  assetManagementSubledgerPermission,
  assetReceivedButNotYetInvoicedPermission,
  assetSubledgerPermission,
  balanceSheetPermission,
  bankDatabasePermission,
  cashAdvancesPermission,
  cashInBankSubLedgerPermission,
  chartOfAccountPermission,
  chartOfAccountTaggingPermission,
  checkVoucherPermission,
  cncCollectionListPermission,
  cncCustomerListPermission,
  cncStatementOfAccountPermission,
  costCenterPermission,
  courierLocationPermission,
  courierPermission,
  creditMemoPermission,
  creditMemoSubLedgerPermission,
  customerAdvancesPermission,
  customerAgingPermission,
  customerCourierPermission,
  customerDealsAndDiscountPermission,
  customerDealsAndDiscountPermissionRegular,
  customerDealsAndDiscountPromoPermission,
  customerListPermission,
  customerPermission,
  customerProductMaintenance,
  customerSummaryPermission,
  cwtSubledgerPermission,
  debitMemoPermission,
  divisionsPermission,
  ewtSubledgerPermission,
  ewtV2SubledgerPermission,
  financeDebitMemoPermission,
  generalLedgerPermission,
  incomeStatementPermission,
  inputVatPermission,
  inventoryAdjustmentPermission,
  inventoryImportPermission,
  inventoryInvoicedSubledgerPermission,
  inventoryReceiptApprovalPermission,
  inventoryReceiptInspectionPermission,
  inventoryReceiptPermission,
  inventoryReceivedNotYetInvoicedPermission,
  inventoryReportMovementPermission,
  inventoryReportMovementTransactionLogPermission,
  inventoryReportPermission,
  inventoryTransferApprovalPermission,
  inventoryTransferPermission,
  inventoryTransferRequestPermission,
  journalVoucherPermission,
  liabilitySubledgerPermission,
  outputVatPermission,
  payableVoucherPermission,
  payeeDatabasePermission,
  paymentApplicationPermission,
  productLedgerPermission,
  productPermission,
  programProjectPermission,
  purchaseOrderPermission,
  purchaseRequestPermssion,
  salePerProductPermission,
  salesReturnPermission,
  statementOfAccountPermission,
  subLedgerInventoryPermission,
  subLedgerJournalPermission,
  subLedgerReceivablePermission,
  supplierDatabasePermission,
  tradeAndNonTradePermission,
  transactionListingPermission,
  treasuryListApprovalPermission,
  treasuryListPermission,
  trialBalancePermission,
  undefinedDepositSubledgderPermission,
  undefinedDepositV2Permission,
  userDatabasePermission,
  cwtReportAndUpdatingModuleFinanceReportPermission,
  countPromoPermission,
  vatableSalesPermission,
  vendorDatabasePermission,
  warehouseAndPalletAssignmentPermission,
  warehousePermission,
  ytdSalesLedgerPermission,
  zoneAsssignmentPermission,
} from './permissions';

const navItems = ({ activeTab }) => [
  {
    id: 'master_databases',
    parent: 'Master Databases',
    icon: masterDBIcon,
    permission: [
      ...productPermission,
      ...customerPermission,
      ...customerProductMaintenance,
      ...customerDealsAndDiscountPermission,
      ...subLedgerJournalPermission,
      ...subLedgerReceivablePermission,
      ...subLedgerInventoryPermission,
      ...warehousePermission,
      ...zoneAsssignmentPermission,
      ...warehousePermission,
      ...salePerProductPermission,
      ...vatableSalesPermission,
      ...vendorDatabasePermission,
      ...supplierDatabasePermission,
      ...payeeDatabasePermission,
      ...bankDatabasePermission,
      ...userDatabasePermission,
      ...inventoryReceivedNotYetInvoicedPermission,
    ],
    child: [
      {
        id: 'product_databases',
        name: 'Product Databases',
        permission: [...productPermission],
        child: [
          {
            id: 'product_list',
            name: 'Product List',
            url: '/products',
            permission: productPermission,
          },
        ],
      },
      {
        id: 'customer_databases',
        name: 'Customer Databases',
        permission: [
          ...customerPermission,
          ...customerProductMaintenance,
          ...customerDealsAndDiscountPermissionRegular,
          ...customerDealsAndDiscountPermission,
        ],
        child: [
          {
            id: 'customer_customer_list',
            name: 'Customer List',
            url: '/customers',
            permission: customerPermission,
          },
          {
            id: 'customer_product_maintenance',
            name: 'Customer Product Maintenance',
            url: '/customers/smpp-product-maintenance',
            permission: customerProductMaintenance,
          },
          {
            id: 'customer_deals_and_discounts_regular',
            name: 'Customer Deals and Discounts (Regular Order)',
            url: '/customers/deals-and-discounts/regular',
            permission: customerDealsAndDiscountPermissionRegular,
          },
          {
            id: 'customer_deals_and_discounts_promotional',
            name: 'Customer Deals and Discounts (Promotional Order)',
            url: '/customers/deals-and-discounts/promotional',
            permission: customerDealsAndDiscountPermission,
          },
          {
            id: 'customer_deals_and_discounts_promotional_new',
            name: 'Customer Deals and Discounts (New Promotional Order)',
            url: '/customers/deals-and-discounts/new',
            permission: customerDealsAndDiscountPromoPermission,
          },
          {
            id: 'customer_deals_and_discounts_logs',
            name: 'Customer Deals and Discounts Logs',
            url: '/customers/deals-logs',
            permission: [
              ...customerDealsAndDiscountPermission,
              ...customerDealsAndDiscountPermissionRegular,
            ],
          },
          {
            id: 'customer_types',
            name: 'Customer Types',
            url: '/3213213',
            hide: true,
          },
          {
            id: 'customer_channels',
            name: 'Customer Channels',
            url: '/54354354',
            hide: true,
          },
        ],
      },
      {
        id: 'courier_databases',
        name: 'Courier Databases',
        permission: [
          ...courierPermission,
          ...courierLocationPermission,
          ...customerCourierPermission,
        ],
        child: [
          {
            id: 'courier_list',
            name: 'Courier List',
            url: '/couriers',
            permission: courierPermission,
          },
          {
            id: 'courier_location',
            name: 'Courier Location',
            url: '/couriers/locations',
            permission: courierLocationPermission,
          },
          {
            id: 'customer_courier',
            name: 'Customer Courier',
            url: '/couriers/customers',
            permission: customerCourierPermission,
          },
        ],
      },
      {
        id: 'sub_ledger_database',
        name: 'Sub Ledger Databases',
        permission: [
          ...subLedgerJournalPermission,
          ...subLedgerReceivablePermission,
          ...subLedgerInventoryPermission,
          ...salePerProductPermission,
          ...vatableSalesPermission,
          ...productLedgerPermission,
          ...ytdSalesLedgerPermission,
        ],
        child: [
          {
            id: 'sales_special_journal',
            name: 'Sales Special Journal',
            url: '/ledgers/sales',
            permission: subLedgerJournalPermission,
          },
          {
            id: 'receivable_sub_ledger',
            name: 'Receivable Sub Ledger',
            url: '/ledgers/receivables',
            permission: subLedgerReceivablePermission,
          },
          {
            id: 'inventory_sub_ledger',
            name: 'Inventory Sub Ledger',
            url: '/ledgers/inventories',
            permission: subLedgerInventoryPermission,
          },
          {
            id: 'sales_per_products_report',
            name: 'Sales Per Products Report',
            url: '/ledgers/sales-per-products',
            permission: salePerProductPermission,
          },
          {
            id: 'vatable_sales',
            name: 'Vatable Sales',
            url: '/ledgers/vatable-sales',
            permission: vatableSalesPermission,
          },
          {
            id: 'product_ledger',
            name: 'Product Ledger',
            url: '/ledgers/products',
            permission: productLedgerPermission,
          },
          {
            id: 'ytd_sales_ledger',
            name: 'YTD Sales Ledger',
            url: '/ledgers/ytd-sales',
            permission: ytdSalesLedgerPermission,
          },
        ],
      },
      {
        id: 'warehouse_database',
        name: 'Warehouse Database',
        permission: [...warehousePermission, ...zoneAsssignmentPermission],
        child: [
          {
            id: 'warehouse_list',
            name: 'Warehouse List',
            url: '/warehouse',
            permission: warehousePermission,
          },
          {
            id: 'zone_assignment',
            name: 'Zone Assignment',
            url: '/warehouse/zone-assignment',
            permission: zoneAsssignmentPermission,
          },
          {
            id: 'zone_type',
            name: 'Zone Type',
            url: '/warehouse/zone-type',
            permission: zoneAsssignmentPermission,
          },
        ],
      },
      {
        id: 'bank_database',
        name: 'Banks Databases',
        url: '/banks-maintenance',
        permission: bankDatabasePermission,
      },
      {
        id: 'user_database',
        name: 'User Database',
        permission: userDatabasePermission,
        child: [
          {
            id: 'user_control',
            name: 'Users Control',
            url: '/user-control',
            permission: userDatabasePermission,
          },
        ],
      },
      {
        id: 'vendor_database',
        name: 'Vendor Database',
        permission: [...vendorDatabasePermission],
        child: [
          {
            id: 'vendors_list',
            name: 'Vendors List',
            url: '/vsp/vendors',
            permission: vendorDatabasePermission,
          },
        ],
      },
      {
        id: 'supplier_database',
        name: 'Supplier Database',
        permission: [...supplierDatabasePermission],
        child: [
          {
            id: 'supplier_list',
            name: 'Supplier List',
            url: '/vsp/supplier',
            permission: supplierDatabasePermission,
          },
        ],
      },
      {
        id: 'payee_database',
        name: 'Payee Database',
        permission: [...payeeDatabasePermission],
        child: [
          {
            id: 'payee_list',
            name: 'Payee List',
            url: '/vsp/payee',
            permission: payeeDatabasePermission,
          },
        ],
      },
    ],
  },
  {
    id: 'inventory',
    parent: 'Inventory',
    icon: inventoryIcon,
    permission: [
      ...inventoryReceiptPermission,
      ...inventoryReceiptInspectionPermission,
      ...inventoryReceiptApprovalPermission,
      ...inventoryAdjustmentPermission,
      ...inventoryReportPermission,
      ...inventoryReportMovementPermission,
      ...inventoryTransferPermission,
      ...inventoryTransferRequestPermission,
      ...inventoryTransferApprovalPermission,
      ...inventoryReportMovementTransactionLogPermission,
      ...inventoryImportPermission,
    ],
    child: [
      {
        id: 'inventory_receipt',
        name: 'Inventory Receipt',
        url: '/inventory/receipts',
        permission: ['receipt_list'],
      },
      {
        id: 'inventory_receipt_inspection',
        name: 'Inventory Receipt Inspection',
        url: '/inventoryreceiptinspection',
        permission: inventoryReceiptInspectionPermission,
      },
      {
        id: 'inventory_receipt_approval',
        name: 'Inventory Receipt Approval',
        url: '/inventoryreceiptapproval',
        permission: inventoryReceiptApprovalPermission,
      },
      {
        id: 'inventory_adjustment',
        name: 'Inventory Adjustment',
        url: '/inventory/adjustment',
        permission: inventoryAdjustmentPermission,
      },
      {
        id: 'inventory_transfer',
        name: 'Inventory Transfer',
        url: '/inventory/transfer',
        permission: [
          ...inventoryTransferPermission,
          ...inventoryTransferRequestPermission,
        ],
        child: [
          {
            id: 'inventory_transfer_v1',
            name: 'Inventory Transfer (Floor to Floor)',
            url: '/inventory/transfer/',
            permission: inventoryTransferPermission,
          },
          {
            id: 'inventory_transfer_v2',
            name: 'Inventory Transfer (Replenishment)',
            url: '/v2/inventory/transfer/' + activeTab,
            permission: inventoryTransferRequestPermission,
          },
          // {
          //   id: "inventory_transfer_approval_v1",
          //   name: "Inventory Transfer Approval (v1)",
          //   url: "/v1/inventory/transfer/approval",
          //   permission: inventoryTransferApprovalPermission,
          // },
          // {
          //   id: "inventory_transfer_version_2",
          //   name: "Inventory Transfer (Version 2)",
          //   url: "/v2/inventory/transfer/list",
          //   permission: inventoryTransferRequestPermission,
          // },
        ],
      },
      // {
      //   id: "inventory_reservation",
      //   name: "Inventory Reservation",
      //   url: "/inventory/reservation",
      //   permission: inventoryReservationPermission,
      // },
      {
        id: 'inventory_report',
        name: 'Inventory Report',
        url: '/inventoryreport',
        permission: inventoryReportPermission,
      },
      {
        id: 'inventory_report_generator',
        name: 'Inventory Report Generator',
        url: '/inventory/report/generator',
        permission: inventoryReportPermission,
      },
      {
        id: 'inventory_movement',
        name: 'Inventory Movement Overview',
        url: '/inventory/movement',
        permission: inventoryReportMovementPermission,
      },
      {
        id: 'inventory_movement_transaction_log',
        name: 'Inventory Movement Transaction Log',
        url: '/inventory/movement-transaction-log/transaction-log',
        permission: inventoryReportMovementTransactionLogPermission,
      },
      {
        id: 'inventory_import',
        name: 'Inventory Import',
        url: '/inventory/import',
        permission: inventoryImportPermission,
      },
      {
        id: 'warehouse',
        name: 'Warehouse',
        permission: [...warehouseAndPalletAssignmentPermission],
        child: [
          {
            id: 'warehouse_and_pallet_assignment',
            name: 'Warehouse & Pallet Assignment',
            url: '/warehouse/pallet/assignment',
            permission: warehouseAndPalletAssignmentPermission,
          },
        ],
      },
    ],
  },
  {
    id: 'credit_and_collections',
    parent: 'Credit and Collections',
    icon: creditCollectionIcon,
    permission: [
      ...creditMemoPermission,
      ...debitMemoPermission,
      ...customerListPermission,
      ...statementOfAccountPermission,
      // Field Officer
      // Admin Officer
      ...treasuryListPermission,
      ...treasuryListApprovalPermission,
      ...paymentApplicationPermission,
      ...customerSummaryPermission,
      ...cncCollectionListPermission,
      ...cncCustomerListPermission,
      //approved by sir charles
      ...customerAgingPermission,
      ...paymentApplicationPermission,
      ...salesReturnPermission,
      ...creditMemoSubLedgerPermission,
      ...undefinedDepositSubledgderPermission,
      ...undefinedDepositV2Permission,
    ],
    child: [
      {
        id: 'cc_customer_list',
        name: 'Customer List',
        url: '/credit-and-collections/management/customers',
        permission: cncCustomerListPermission,
      },
      {
        id: 'cc_collections_list',
        name: 'Collections List',
        url: '/credit-and-collections/management/collections',
        permission: cncCollectionListPermission,
      },
      {
        id: 'cc_collections_list_access',
        name: 'Collections List Access',
        url: '/credit-and-collections/management/collections-access',
        permission: [],
      },
      {
        id: 'cc_customer_summary',
        name: 'Customer Summary',
        url: '/credit-and-collections/management/customer-center',
        permission: customerSummaryPermission,
      },
      {
        id: 'cc_customer_aging_summary',
        name: 'Customer Aging Summary',
        url: '/credit-and-collections/management/customer-aging',
        permission: customerAgingPermission,
      },
      {
        id: 'cc_customer_payment_application',
        name: 'Payment Application',
        url: '/credit-and-collections/management/payment-application',
        permission: paymentApplicationPermission,
      },
      {
        id: 'cc_sales_return_pull_out',
        name: 'Sales Return Pull-out (SRPF)',
        url: '/collection/srpf',
        permission: salesReturnPermission,
      },
      {
        id: 'cc_treasury',
        name: 'Treasury',
        permission: [
          ...treasuryListPermission,
          ...treasuryListApprovalPermission,
        ],
        child: [
          {
            id: 'treasury_list',
            name: 'Treasury List',
            url: '/treasury/trade/pending',
            permission: treasuryListPermission,
          },
          {
            id: 'treasury_approval_list',
            name: 'List of Approvals',
            url: '/treasury/list-of-approvals',
            permission: treasuryListApprovalPermission,
          },
        ],
      },
      {
        id: 'cc_credit_memo',
        name: 'Credit Memo',
        url: '/credit-and-collections/management/memo/credit',
        permission: [...creditMemoPermission, ...creditMemoSubLedgerPermission],
        child: [
          {
            id: 'cc_credit_memo_list',
            name: 'List',
            url: '/credit-and-collections/management/memo/credit',
            permission: creditMemoPermission,
          },
          {
            id: 'fr_credit_memo_subledger',
            name: 'Receivable Deductions',
            url: '/credit-and-collections/management/memo-credit/credit-memo-subledger',
            permission: creditMemoSubLedgerPermission,
          },
        ],
      },
      {
        id: 'cc_debit_memo',
        name: 'Debit Memo',
        url: '/credit-and-collections/management/memo/debit',
        permission: debitMemoPermission,
      },
      {
        id: 'cc_sales_invoice_manual_entry',
        name: 'Sales Invoice Manual Entry',
        url: '/sales-invoice/manual/entry',
        permission: [],
      },
      {
        id: 'cc_customer_advances_report',
        name: 'Customer Advances Report',
        url: '/credit-and-collections/customer-advances-report',
        permission: customerAdvancesPermission,
      },
      {
        id: 'cc_statement_of_account',
        name: 'Generate Statement of Account',
        url: '/credit-and-collections/management/statement-of-account',
        permission: cncStatementOfAccountPermission,
      },
    ],
  },
  {
    id: 'purchasing',
    parent: 'Purchasing',
    icon: creditCollectionIcon,
    permission: [...purchaseRequestPermssion, ...purchaseOrderPermission],
    child: [
      {
        id: 'purchase_request',
        name: 'Purchase Request',
        url: '/purchasing/purchaseRequest/approval',
        permission: purchaseRequestPermssion,
      },
      {
        id: 'purchase_order',
        name: 'Purchase Order',
        url: '/purchase-order',
        // permission: customerAdvancesPermission,
        permission: purchaseOrderPermission,
      },
    ],
  },

  {
    id: 'finance_and_accounting',
    parent: 'Finance and Accounting',
    icon: creditCollectionIcon,
    permission: [
      ...chartOfAccountPermission,
      ...payableVoucherPermission,
      ...chartOfAccountTaggingPermission,
      ...costCenterPermission,
      ...divisionsPermission,
      ...programProjectPermission,
      ...assetManagementPurchaseOrderNSPermission,
      ...assetManagementSubledgerPermission,
      ...assetManagementListingsPermission,
      ...assetSubledgerPermission,
      ...assetExpenseSubledgerPermission,
      ...journalVoucherPermission,
      ...balanceSheetPermission,
      ...financeDebitMemoPermission,
      ...checkVoucherPermission,
      ...trialBalancePermission,
      ...generalLedgerPermission,
      ...incomeStatementPermission,
      ...inventoryInvoicedSubledgerPermission,
      ...tradeAndNonTradePermission,
      ...transactionListingPermission,
      ...ewtSubledgerPermission,
      ...cashInBankSubLedgerPermission,
      ...ewtV2SubledgerPermission,
      'bypass',
      ...cwtSubledgerPermission,
      ...cwtReportAndUpdatingModuleFinanceReportPermission,
      ...cashAdvancesPermission,
      ...advancesFromCustomerPermission,
    ],
    child: [
      {
        id: 'fna_database',
        name: 'Database',
        permission: [
          ...chartOfAccountPermission,
          ...chartOfAccountTaggingPermission,
          ...costCenterPermission,
          ...divisionsPermission,
          ...programProjectPermission,
        ],
        child: [
          {
            id: 'chart_of_account',
            name: 'Chart of Account',
            url: '/finance-and-accounting/chart-of-account',
            permission: [
              ...chartOfAccountPermission,
              ...chartOfAccountTaggingPermission,
            ],
            child: [
              {
                id: 'chart_of_account_list',
                name: 'List',
                url: '/finance-and-accounting/chart-of-account',
                permission: chartOfAccountPermission,
              },
              {
                id: 'chart_of_account_tagging',
                name: 'Tagging',
                url: '/finance-and-accounting/coa-tagging',
                permission: chartOfAccountTaggingPermission,
              },
            ],
          },
          {
            id: 'am_cost_center',
            name: 'Cost Center',
            url: '/finance-and-accounting/cost-center',
            permission: costCenterPermission,
          },
          {
            id: 'am_divisions',
            name: 'Divisions',
            url: '/finance-and-accounting/divisions',
            permission: divisionsPermission,
          },
          {
            id: 'program_project',
            name: 'Programs & Projects',
            url: '/finance-and-accounting/program-project',
            permission: programProjectPermission,
          },
        ],
      },
      {
        id: 'fna_processing_module',
        name: 'Processing Module',
        permission: [
          ...payableVoucherPermission,
          ...journalVoucherPermission,
          ...checkVoucherPermission,
          ...financeDebitMemoPermission,
          ...assetManagementPurchaseOrderNSPermission,
          ...assetManagementSubledgerPermission,
          ...assetManagementListingsPermission,
          'bypass',
        ],
        child: [
          {
            id: 'payable_voucher',
            name: 'Payable Voucher',
            url: '/finance-and-accounting/payable-voucher/all',
            permission: payableVoucherPermission,
          },
          {
            id: 'journal_voucher',
            name: 'Journal Voucher',
            url: '/finance-and-accounting/journal-voucher/all',
            permission: journalVoucherPermission,
          },
          {
            id: 'check_voucher',
            name: 'Check Voucher',
            url: '/finance-and-accounting/check-voucher/pending-payment-vouchers',
            permission: checkVoucherPermission,
          },
          {
            id: 'finance_debit_memo',
            name: 'Debit Memo',
            url: '/debit-memo/creation-of-dm',
            permission: financeDebitMemoPermission,
          },
          {
            id: 'am_asset_management',
            name: 'Asset Management',
            permission: [
              ...assetManagementPurchaseOrderNSPermission,
              ...assetManagementSubledgerPermission,
              ...assetManagementListingsPermission,
            ],
            child: [
              {
                id: 'asset_management_purchase_order_ns',
                name: 'PO - Non Stock',
                url: '/finance-and-accounting/asset-management/purchase-order-non-stock',
                permission: assetManagementPurchaseOrderNSPermission,
              },
              {
                id: 'asset_management_listings',
                name: 'Listings',
                url: '/finance-and-accounting/asset-management/list',
                permission: assetManagementListingsPermission,
              },
            ],
          },
          {
            id: 'po_management',
            name: 'PO Management',
            url: '/payable-management/stock-po-report',
            permission: ['bypass'],
          },
        ],
      },
      {
        id: 'fna_financial_reports',
        name: 'Financial Reports',
        permission: [
          ...assetSubledgerPermission,
          ...assetExpenseSubledgerPermission,
          ...trialBalancePermission,
          ...generalLedgerPermission,
          ...incomeStatementPermission,
          ...balanceSheetPermission,
          ...inventoryInvoicedSubledgerPermission,
          ...tradeAndNonTradePermission,
          ...transactionListingPermission,
          ...cwtSubledgerPermission,
          ...ewtSubledgerPermission,
          ...cashInBankSubLedgerPermission,
          ...cwtReportAndUpdatingModuleFinanceReportPermission,
          ...outputVatPermission,
          ...inputVatPermission,
          ...advancesToVendorPermission,
          ...cashAdvancesPermission,
          ...assetReceivedButNotYetInvoicedPermission,
          ...ewtV2SubledgerPermission,
          ...advancesFromCustomerPermission,
          ...advancesToSuppliersPermission,
        ],
        child: [
          {
            id: 'subsidiary_ledger',
            name: 'Subsidiary Ledger',
            permission: [
              ...assetSubledgerPermission,
              ...assetExpenseSubledgerPermission,
              ...liabilitySubledgerPermission,
              ...tradeAndNonTradePermission,
              ...cashInBankSubLedgerPermission,
            ],
            child: [
              {
                id: 'fr_asset_title',
                name: 'Assets',
                type: 'title',
                permission: [...assetSubledgerPermission],
              },
              {
                id: 'fr_asset_sub_ledger',
                name: 'Capitalized Assets',
                url: '/finance-and-accounting/capitalized-assets',
                indent: true,
                permission: assetSubledgerPermission,
              },
              {
                id: 'fr_assest_divider',
                name: 'Assets',
                type: 'divider',
                permission: [...assetSubledgerPermission],
              },
              {
                id: 'fr_liabilities_title',
                name: 'Liabilities',
                type: 'title',
                permission: [...liabilitySubledgerPermission],
              },
              {
                id: 'fr_liabilties_subledger',
                name: 'Current Liabilties',
                url: '/finance-and-accounting/current-liabilities',
                indent: true,
                permission: liabilitySubledgerPermission,
              },
              {
                id: 'fr_liabilities_divider',
                name: 'Liabilities',
                type: 'divider',
                permission: [...liabilitySubledgerPermission],
              },
              {
                id: 'fr_expense_title',
                name: 'Expenses',
                type: 'title',
                permission: [...assetExpenseSubledgerPermission],
              },
              {
                id: 'fr_expense_subledger',
                name: 'Allocated Expenses',
                url: '/finance-and-accounting/allocated-expenses',
                indent: true,
                permission: assetExpenseSubledgerPermission,
              },
              {
                id: 'fr_trade_title',
                name: 'Trade and Non-trade',
                type: 'title',
                permission: [...tradeAndNonTradePermission],
              },
              {
                id: 'fr_trade_non_trade',
                name: 'Trade and Non-trade',
                url: '/finance-and-accounting/separation-of-trade-non-trade-payables',
                indent: true,
                permission: tradeAndNonTradePermission,
              },
              {
                id: 'fr_cash_in_bank_subledger',
                name: 'Cash In Bank',
                url: '/finance-and-accounting/cash-in/bank/subledgder',
                indent: true,
                permission: [...cashInBankSubLedgerPermission],
              },
            ],
          },
          {
            id: 'general_ledger',
            name: 'General Ledger',
            url: '/finance-and-accounting/general-ledger',
            permission: generalLedgerPermission,
          },
          {
            id: 'trial_balance',
            name: 'Trial Balance',
            url: '/finance-and-accounting/trial-balance',
            permission: trialBalancePermission,
          },
          {
            id: 'income_statement',
            name: 'Income Statement',
            url: '/finance-and-accounting/income-statement',
            permission: incomeStatementPermission,
          },
          {
            id: 'balance_sheet',
            name: 'Balance Sheet',
            url: '/finance-and-accounting/balance-sheet',
            permission: balanceSheetPermission,
          },
          {
            id: 'inventory_invoiced_subledger',
            name: 'Inventory Receipt but not yet Invoiced Subledger',
            url: '/finance-and-accounting/inventory-invoiced-subledger',
            permission: inventoryInvoicedSubledgerPermission,
          },
          {
            id: 'transaction_listing',
            name: 'Transaction Listing',
            url: '/finance-and-accounting/transaction-listing',
            permission: transactionListingPermission,
          },
          {
            id: 'advances_to_suppliers',
            name: 'Advances to Suppliers',
            url: '/finance-and-accounting/advances-to-suppliers',
            permission: advancesToSuppliersPermission,
          },
          {
            id: 'cwt_subledger',
            name: 'CWT',
            url: '/finance-and-accounting/cwt',
            permission: cwtSubledgerPermission,
          },

          {
            id: 'cwt_report_and_updating_module',
            name: 'CWT Report & Updating Module',
            url: '/finance-and-accounting/cwt-report-and-updating-module',
            permission: cwtReportAndUpdatingModuleFinanceReportPermission,
          },
          // {
          //   id: 'ewt_subledger',
          //   name: 'Ewt Subledger',
          //   url: '/finance-and-accounting/ewt-subledger',
          //   permission: ewtSubledgerPermission,
          // },
          {
            id: 'ewt_subledger_v2',
            name: 'EWT',
            url: '/finance-and-accounting/ewt',
            permission: ewtV2SubledgerPermission,
          },
          {
            id: 'ir_not_yet_invoice',
            name: 'Inventory Received but not yet Invoiced',
            url: '/finance-and-accounting/ir-not-yet-invoice',
            permission: inventoryReceivedNotYetInvoicedPermission,
          },
          {
            id: 'output_vat',
            name: 'Output Vat',
            url: '/finance-and-accounting/output-vat',
            permission: outputVatPermission,
          },

          {
            id: 'input_vat',
            name: 'Input Vat',
            url: '/finance-and-accounting/input-vat',
            permission: inputVatPermission,
          },
          {
            id: 'advances_to_vendor_permission',
            name: 'Advances To Vendor',
            url: '/finance-and-accounting/advances-to-vendors',
            permission: advancesToVendorPermission,
          },
          {
            id: 'asset_received_but_not_yet_invoiced',
            name: 'Asset Received But Not Yet Invoiced',
            url: '/finance-and-accounting/asset-received-but-not-yet-invoiced',
            permission: assetReceivedButNotYetInvoicedPermission,
          },
          {
            id: 'advances_subledgder',
            name: 'Advances from Customers',
            url: '/finance-and-accounting/advances-from-customers',
            permission: advancesFromCustomerPermission,
          },
          {
            id: 'cash_advances',
            name: 'Cash Advances',
            permission: [...cashAdvancesPermission],
            child: [
              {
                id: 'cash_advances_setup',
                name: 'CA Setup',
                url: '/finance-and-accounting/cash-advance/setup',
                permission: cashAdvancesPermission,
              },
              {
                id: 'cash_advances_override',
                name: 'CA Override',
                url: '/finance-and-accounting/cash-advance/override',
                permission: cashAdvancesPermission,
              },
              {
                id: 'cash_advances_subledger',
                name: 'CA Subledger',
                permission: [...cashAdvancesPermission],
                child: [
                  {
                    id: 'ca_subledger_all',
                    name: 'All',
                    url: '/finance-and-accounting/cash-advance/subledger/all',
                    permission: cashAdvancesPermission,
                  },
                  {
                    id: 'ca_subledger_per_employee',
                    name: 'Per Employee',
                    url: '/finance-and-accounting/cash-advance/subledger/employee',
                    permission: cashAdvancesPermission,
                  },
                  {
                    id: 'cash_advanced_aging',
                    name: 'Cash Advanced Aging',
                    url: '/finance-and-accounting/cash-advanced-aging',
                    permission: cashAdvancesPermission,
                  },
                ],
              },
            ],
          },

          // {
          //   id: "fr_expense_divider",
          //   name: "Liabilities",
          //   type: "divider",
          //   permission: [...assetExpenseSubledgerPermission],
          // },
          {
            id: 'undefined_deposit',
            name: 'Undefined Deposit (UDD)',
            permission: [
              ...undefinedDepositSubledgderPermission,
              ...undefinedDepositV2Permission,
            ],
            child: [
              {
                id: 'undefined_deposit_subledger_v2',
                name: 'Undefined Deposit Subledgder for JV v2 (New)',
                url: '/finance-and-accounting/undefined-deposit-subledger-v2',
                permission: undefinedDepositV2Permission,
              },
              {
                id: 'undefined_deposit_subledgder',
                name: 'Undefined Deposit Subledgder (Old)',
                url: '/finance-and-accounting/undefined/deposit/subledger',
                permission: undefinedDepositSubledgderPermission,
              },
            ],
          },
        ],
      },
    ],
  },
];

export default navItems;
